import React, { useState, useContext, useEffect, useRef} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import {AppContext} from '../../App'; 
 

export default function AccountManagement() {

  const navigate = useNavigate(); 
   
  const {globalUserData, setGlobalUserData} = useContext(AppContext)
  

  const location = useLocation()
  const userData = location.state.userData


  const [numValue , setNumValue] = useState(userData.accNum)
  const [nameValue , setNameValue] = useState(userData.accName)

  let bank = userData.accBank
  if(bank == "")
    bank = "KB국민은행"
 
  function handleBackClick() {
    navigate(-1)
  }
   
  async function handleSaveClick() { 
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum 
    let params = {accBank : bank, accNum : numValue, accName: nameValue }

    await fetch(url, {     
    method : "PUT",         
    body: JSON.stringify(params)   
    })
    .then(response=>{  
    return response.json();
    })
    .then(data=>{   
      if(data.code == "error")
        {
          alert(data.message);
          return;
        }
 
      alert("저장되었습니다.")
      handleBackClick()
      
    })
    .catch(err => {
        alert(err.message);
    }); 

  }


  const changeBankSelect = (event) =>{
    bank = event.target.value
};


return (
  <div className='serviceSelect'>
  <div className='inner32'>
    <div className='pageBack' onClick={handleBackClick}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"/>
      </svg>
    </div>
    <h1 className='pageTit' style={{
      marginBottom:'43px'
    }}>정산 계좌 관리 </h1>
  </div>

  <div className='inner'>
    <div className='inputWrap'>
      <div className='label'>은행</div>
      <select className='input borderSelect' name="selectBank" defaultValue={userData.accBank} onChange={changeBankSelect}>
        <option key="1" value="KB국민은행">KB국민은행</option>
        <option key="2"  value="신한은행">신한은행</option>
        <option key="3"  value="KEB하나은행"> KEB하나은행</option>
        <option key="4"  value="우리은행">우리은행</option>
        <option key="5"  value="IBK기업은행">IBK기업은행</option>
        <option key="6"  value="SC제일은행">SC제일은행</option>
        <option key="7"  value="NH농협은행">NH농협은행</option>
        <option key="8"  value="수협은행">수협은행</option>
        <option key="9"  value="토스뱅크">토스뱅크</option>
        <option key="10"  value="케이뱅크">케이뱅크</option>
        <option key="11"  value="카카오뱅크">카카오뱅크</option>
        <option key="12"  value="토스뱅크">토스뱅크</option>
        <option key="13"  value="새마을금고">새마을금고</option>
        <option key="14"  value="우체국">우체국</option>
        <option key="15"  value="신용협동조합">신용협동조합</option>
        <option key="16"  value="경남은행">경남은행</option>
        <option key="17"  value="부산은행">부산은행</option>
        <option key="18"  value="광주은행">광주은행</option>
        <option key="19"  value="제주은행">제주은행</option>
        <option key="20"  value="전북은행">전북은행</option>
      </select>
    </div>
    <div className='inputWrap'>
      <div className='label'>계좌번호</div>
      <input className='input' value={numValue} onChange={(e) => {
          setNumValue(e.target.value);
        }}></input>
    </div>
    <div className='inputWrap'>
      <div className='label'>예금주</div>
      <input className='input' value={nameValue} onChange={(e) => {
          setNameValue(e.target.value);
        }}></input>
    </div>

    <div style={{
      marginTop:'42px',
      fontSize:'14px',
      lineHeight:'1.2'
    }}>
      정산 계좌 번호가 틀릴 경우<br/>
      정산에 어려움이 있습니다.<br/><br/>
      계좌번호를 정확하게 입력해 주세요.
    </div>

    <div className='ctaWrap bottomCta'><button className='cta' onClick={handleSaveClick}>확인</button></div>
  </div>
</div>
  )
};