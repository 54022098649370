import React from 'react';
import { Nav, NavItem} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faHome, faUserCircle } from '@fortawesome/free-solid-svg-icons';
// import "../../src/css/bottomNav.css"

const tabs = [{
  route: "/",
  icon: "home",
  label: "홈"
},{
  route: "/Usage",
  icon: "status",
  label: "이용현황"
},{
  route: "/Mypage",
  icon: "profile",
  label: "내정보"
}]

const BottomNavigation = (props) => {return (
    <div>  
     
      {/* Bottom Tab Navigator*/}
      <nav className="navbar fixed-bottom navbar-light bottom-tab-nav" role="navigation">
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {
              tabs.map((tab, index) =>(
                <NavItem key={`tab-${index}`}>
                  <NavLink replace="true" to={tab.route} className="nav-link bottom-nav-link" activeclassname="active">
                    <div className="row d-flex flex-column justify-content-center align-items-center">
                      {/* <FontAwesomeIcon size="lg" icon={tab.icon}/> */}
                      <div className={"icon " + tab.icon}></div>
                      <div className="bottom-tab-label">{tab.label}</div>
                    </div>
                  </NavLink>
                </NavItem>
              ))
            }
          </div>
        </Nav>
      </nav>
    </div>
  )
};


export default BottomNavigation;