import React, { useState, useContext, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import {AppContext} from '../../App';
import ServiceDataDetailView from './ServiceDataDetailView';
 
import * as moment from 'moment';
import { ServiceDataPaymentShortlView } from './ServiceDataShortView';

function OngoingDetailItem(props) { 
   
    const navigate = useNavigate(); 
    const [supporterData, setSupporterData] = useState({userName:"", refer:""})

    function handleSupporterSelectClick() {
      navigate("/Usage/SupporterSelect", { replace: false, state : {servicedata: props.data}})
    }
    function handleSupporterCheckClick() {
      navigate("/Usage/SelectedServiceDetail", { replace: false})
    }

    function handleBackClick() { 
        navigate(-1)
    }
   
    function handleSupporterClick() {
      navigate("/Usage/ShowSupporterInfo", { replace: false, state : {supseqnum: props.data.selectedSup}})
    }

    let bShowSelect = false
    let bShowCheck = false

    let canModify = false
  
    if(props.data.status == 0)
    {

      const now = moment()
      const startDate = moment(now.format("YYYY-MM-DD"), "YYYY-MM-DD")
      const endDate = moment(props.data.serviceDate, "YYYY년 MM월 DD일")
      const daysDifference = endDate.diff(startDate, 'days');

      if(daysDifference<0)
      {
        bShowSelect = false
        bShowCheck = false
      }
      else
      {
        bShowSelect = true
        bShowCheck = false
      }

      if(props.data.supCount == 0) {
        canModify = true
      }
      else {
        canModify = false
      }
        
    }
    else if(props.data.status == 1) 
    {       
        bShowSelect = false
        bShowCheck = true

        canModify = false
    }
    else if(props.data.status == 5) 
    {
        bShowSelect = false
        bShowCheck = false

        if(props.data.supCount == 0) {
          canModify = true
        }
        else {
          canModify = false
        }
    }



  const initRead = async() => {
  
    if((props.data.status == 1) | (props.data.status == 2))
    {        
      let url = "https://rest.besideme.life/rest/v1/servicesuggestdata/" + props.data.suggestSeqNum 

      await fetch(url)
      .then(response=>{ 
          return response.json();
      })
      .then(data=>{  
        if(data.code == "error")
          {
            alert(data.message);
            return;
          }
          setSupporterData(data.result) 
      })
      .catch(err => {
          alert(err.message);
      }); 

    }
  }

    useEffect(() => {initRead()},[]);

    return (
      <>
        <div className='bg' style={{marginTop:'5px'}}>
          <div className='card'>
          <ServiceDataDetailView data={props.data} canModify={canModify}>></ServiceDataDetailView>
          <ServiceDataPaymentShortlView isSupporter={false} hidden={ !((props.data.status == 1) | (props.data.status == 2))} data={props.data}></ServiceDataPaymentShortlView>
  
          <div hidden={ !((props.data.status == 1) | (props.data.status == 2))} className="section" >
              <div className='profileWrap' style={{
              gap:'38px',
              alignItems:'flex-start',
              marginBottom:'45px'
            }}>
            <div className='column'>
              <div className='profile badge' onClick={handleSupporterClick}>
                <img src={"https://buddypics.s3.ap-northeast-2.amazonaws.com/"+supporterData.supSeqNum+".jpg?${new Date()}"} onError={(e) => e.target.src = 'https://imgcdn.stablediffusionweb.com/2024/6/12/4d688bcf-f53b-42b6-a98d-3254619f3b58.jpg'}></img>
                <svg className='checkBadge' width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.6072 11.0791L27.615 10.9551L28.6072 11.0791C28.7178 10.1947 28.2297 9.34386 27.4105 8.99275L18.2878 5.08302C17.7847 4.86741 17.2152 4.86741 16.7121 5.08302L7.58941 8.99275C6.77017 9.34385 6.28214 10.1947 6.39269 11.0791L7.46401 19.6497C7.68416 21.4109 8.56513 23.0227 9.92866 24.159L16.2196 29.4014C16.9613 30.0195 18.0386 30.0195 18.7803 29.4014L25.0713 24.159C26.4348 23.0227 27.3158 21.4109 27.5359 19.6497L28.6072 11.0791Z" fill="#45D88E" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                <path d="M13.125 17.5L17.0688 21.4437C17.2919 21.6668 17.6633 21.6301 17.8384 21.3675L23.3334 13.125" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div> 
            </div>
            <h3 className='pageTit' style={{
                  margin:'0', fontSize:'18px'
                }}>{supporterData.userName}</h3>
            </div>


              <div className='value'>참고해 주세요</div>
              <pre style={{whiteSpace: "pre-wrap"}}>{supporterData.refer}</pre>
              </div>   
            </div>
          </div> 
        <div className='goldBox'>  
        <div className='font16' hidden={!(props.data.supCount == 0) | !(props.data.status == 0)} style={{
            marginTop:'-14px'
          }}>선택할 수 있는 서포터가 아직 없습니다</div>

          <div className='btnWrap' style={{marginTop:'0'}}>
            <button className='btn primary' onClick={handleBackClick}>확인</button>
            <button className={'btn primary ' + ((props.data.supCount == 0)?'disabled':'')} style={{borderColor:'rgba(1,1,1,0)'}} disabled={props.data.supCount == 0} hidden={!bShowSelect} onClick={handleSupporterSelectClick}>서포터 선택하기</button>
            <button className='btn primary' hidden={/*!bShowCheck*/ true} onClick={handleSupporterCheckClick}>서포터 확인하기</button>
          </div>
        </div>
      </>
    )
  }
  
   
  function OngoingSupporterDetailItem(props) {
    const [showDetail, setShowDetail] = useState(false)
   
    const navigate = useNavigate(); 
     
    function handleBackClick() { 
        navigate(-1)
    }
    
    function numberWithCommas(x) {
      if(x==undefined)
        return "0"
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
      <>
      <div className='bg OngoingDetail' style={{marginTop:'30px'}}>
  
        <div className='card'>
        <ServiceDataDetailView data={props.data} canModify={false}>></ServiceDataDetailView>
         
         </div>
         <div className="card">
            <p><div className='value'>신청사유</div></p>
            <pre style={{whiteSpace: "pre-wrap"}}>{props.data.reason}</pre>
            <div style={{marginTop:'24px'}}>
              <div className='between' >
                <div className='value'>서포터 비용(시간당)</div> {numberWithCommas(props.data.price)}원
              </div>
            </div>
            <div style={{marginTop:'24px'}}>
              <div className='value'>참고해 주세요</div>
              <pre style={{whiteSpace: "pre-wrap"}}>{props.data.refer}</pre>
            </div>     

        </div>
      </div>

      <div className='bg'  style={{
        paddingBottom:'44px'
      }}>
      </div>
      <div className='goldBox'>

        <button className='cta' onClick={handleBackClick} style={{
          height:'45px',
          flexBasis:'auto'
        }}>확인</button> 
      </div>
      </>
    )
  }
  
  
  export default function OngoingDetail() {
  
    const navigate = useNavigate(); 
    
    const {globalUserData, setGlobalUserData, modeSupporter, setModeSupporter} = useContext(AppContext); 
 //   const[serviceData, setServiceData] = useState([])
    const [isReady, setIsReady] = useState(false)
  

    const location = useLocation();	
    const servicedata = location.state.servicedata;	


    function handleBackClick() { 
        navigate(-1)
    }

 

    const initRead = async() => {
   /*
      if(globalUserData.userSeqNum == 0)
        return
  
      let url = "";
      
      if(modeSupporter)
        url = "https://rest.besideme.life/rest/v1/supporters/"+globalUserData.userSeqNum+"/servicelist&statusType=0" 
      else
        url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/servicelist&statusType=0"
  
      await fetch(url)
      .then(response=>{ 
          
          return response.json();
      })
      .then(data=>{  
        if(data.code == "error")
          {
            alert(data.message);
            return;
          }
     */
        setIsReady(true)
    /*
        setServiceData(data.result)
      })
      .catch(err => {
          alert(err.message);
      });
      */ 
    }
    useEffect(() => {initRead()},[]);

    const [menuOption, setMenuOption] = useState('')

    function menuOptionToggle() {
        menuOption === 'ac'?setMenuOption(''):setMenuOption('ac')
    }
 
    async function handleCancel() {

      if( (servicedata.status == 0) & (servicedata.supCount == 0) )
      {

        let url = "https://rest.besideme.life/rest/v1/servicedata/"+servicedata.serviceSeqNum; 
    
        await fetch(url, {
          method : "DELETE",        
        })
        .then(response=>{  
          return response.json();
        })
        .then(data=>{  
          if(data.code == "error")
            {
              alert(data.message);
              return;
            }
             
            handleBackClick()
        })
        .catch(err => {
            alert(err.message);
        }); 
     
      }
      else if( (servicedata.status == 0) & (servicedata.supCount > 0) )
      {
          alert("서포터 지원자가 있는 건은 직접 삭제할 수 없습니다. 고객 센터에 문의해 주세요!")
      }
      else if(servicedata.status == 1)
      {
          alert("서포터가 확정된 건은 직접 삭제할 수 없습니다. 고객 센터에 문의해 주세요!")
      }
      else {
        if(window.confirm("도움 요청을 취소 하시겠습니까?") == true) 
        {
          let url = "https://rest.besideme.life/rest/v1/servicedata/"+servicedata.serviceSeqNum;
          let params = {status : 10}
      
          await fetch(url, {
            method : "PUT",       
            body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
          })
          .then(response=>{  
            return response.json();
          })
          .then(data=>{  
            if(data.code == "error")
              {
                alert(data.message);
                return;
              }
               
              handleBackClick()
          })
          .catch(err => {
              alert(err.message);
          }); 
        }
      }

      menuOptionToggle()
    }

    async function handleSupporterCancel() {
      menuOptionToggle()

      if(servicedata.status == 1) {
        alert("서포터가 확정된 건은 직접 삭제할 수 없습니다. 고객 센터에 문의해 주세요!")
      }
      else {
        if(window.confirm("서포터 신청을 취소 하시겠습니까?") == true) 
        {

            let url = "https://rest.besideme.life/rest/v1/suggestservicedata/"+servicedata.suggestSeqNum; 
        
            let params = {serviceSeqNum : servicedata.serviceSeqNum}
      

            await fetch(url, {
              method : "DELETE",  
              body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장     
            })
            .then(response=>{  
              return response.json();
            })
            .then(data=>{  
              if(data.code == "error")
                {
                  alert(data.message);
                  return;
                }
                
                alert("삭제합니다.")
                handleBackClick()
            })
            .catch(err => {
                alert(err.message);
            }); 
        }
      }

    }

    if(modeSupporter)
    {
      return (
      <div className='subPage serviceSelect'>
            <div className="menuOption" style={{
            position:'absolute',
            top:'14px',
            right:'12px'
          }}>
            
            <svg onClick={menuOptionToggle} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="6" r="1.5" fill="black"/>
                <circle cx="12" cy="12" r="1.5" fill="black"/>
                <circle cx="12" cy="18" r="1.5" fill="black"/>
            </svg>
            <div className={`menu ${menuOption}`}>
                <div className="dropMenu">
                    <button onClick={handleSupporterCancel}>삭제하기</button>
                </div>
                <div className="dim" onClick={menuOptionToggle}></div>
            </div>
            
          </div>

          <div className="inner32">
            <div className="pageTit">{servicedata.serviceDate}<br/>서포터 신청내역</div>
          </div>
          
          <div className=''> 
            <div className="pageBack" onClick={handleBackClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
            </div>
            {/* <div className="moreMenu" onClick={handleSupporterMoreClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm0-2.6A2.2 2.2 0 1 0 9.999.8a2.2 2.2 0 0 0 .002 4.4m0 9.6a2.2 2.2 0 1 0 0 4.402a2.2 2.2 0 0 0 0-4.402"/></svg>
            </div> */}
            <OngoingSupporterDetailItem data={servicedata} />
          </div>
      </div>


      )  
    }
    else
    {

      return (            
        
      <div className='subPage serviceSelect'>
          <div className="menuOption" style={{
            position:'absolute',
            top:'14px',
            right:'12px'
          }}>
            
            <svg onClick={menuOptionToggle} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="6" r="1.5" fill="black"/>
                <circle cx="12" cy="12" r="1.5" fill="black"/>
                <circle cx="12" cy="18" r="1.5" fill="black"/>
            </svg>
            <div className={`menu ${menuOption}`}>
                <div className="dropMenu">
                    <button onClick={handleCancel}>요청 취소하기</button>
                </div>
                <div className="dim" onClick={menuOptionToggle}></div>
            </div>
            
          </div>
          <div className="inner32">
            <div className="pageTit">{servicedata.serviceDate}<br/>요청내역</div>
          </div>
          <div className=''>
            <div className="pageBack" onClick={handleBackClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
            </div>             
            {/* <div className="moreMenu" onClick={handleMoreClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm0-2.6A2.2 2.2 0 1 0 9.999.8a2.2 2.2 0 0 0 .002 4.4m0 9.6a2.2 2.2 0 1 0 0 4.402a2.2 2.2 0 0 0 0-4.402"/></svg>
            </div> */}
            <OngoingDetailItem data={servicedata} />
          </div>
      </div>


      )  
    } 

  };
   