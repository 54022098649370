import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import { AppContext } from '../../App';
 
 function SupporterItem(props) {

  const navigate = useNavigate();

  function handleSupporterDetail() {
    navigate("/Usage/SupporterSelectDetail", { replace: false, state : {serviceData: props.servicedata, supporterData: props.data}})
  }


  function handleSupporterClick() {
    navigate("/Usage/ShowSupporterInfo", { replace: false, state : {supseqnum: props.data.userSeqNum}})
  }

  return(
    <div className='card' style={{
      marginBottom:'13px'
    }} key={props.key}>
      <div>
        <div className='profileWrap' style={{
          gap:'38px',
          alignItems:'flex-start',
          marginBottom:'45px'
        }}>
          <div className='column'>
            <div className='profile badge' onClick={handleSupporterClick}>
              <img src={"https://buddypics.s3.ap-northeast-2.amazonaws.com/"+props.data.supSeqNum+".jpg?${new Date()}"} onError={(e) => e.target.src = 'https://imgcdn.stablediffusionweb.com/2024/6/12/4d688bcf-f53b-42b6-a98d-3254619f3b58.jpg'}></img>
              <svg hidden={props.data.isCertified == 0} className='checkBadge' width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M28.6072 11.0791L27.615 10.9551L28.6072 11.0791C28.7178 10.1947 28.2297 9.34386 27.4105 8.99275L18.2878 5.08302C17.7847 4.86741 17.2152 4.86741 16.7121 5.08302L7.58941 8.99275C6.77017 9.34385 6.28214 10.1947 6.39269 11.0791L7.46401 19.6497C7.68416 21.4109 8.56513 23.0227 9.92866 24.159L16.2196 29.4014C16.9613 30.0195 18.0386 30.0195 18.7803 29.4014L25.0713 24.159C26.4348 23.0227 27.3158 21.4109 27.5359 19.6497L28.6072 11.0791Z" fill="#45D88E" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
              <path d="M13.125 17.5L17.0688 21.4437C17.2919 21.6668 17.6633 21.6301 17.8384 21.3675L23.3334 13.125" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </div>

            {props.data.userName}
          </div>
          <div style={{
            fontSize:'14px',
            fontWeight:'400',
            wordBreak:'break-all'
          }}>
           <pre style={{
            fontSize:'14px',
            fontWeight:'400',
            wordBreak:'break-all',
            whiteSpace: "pre-wrap"
          }}>{/*{props.data.supDesc} <br/><br/>*/}
            {props.data.reason}</pre>
          </div>
        </div>
      </div>

      <div className='section'>
              {/*
        <div className="starWrapBox">
            시간 약속을 잘 지켜요
            <div className="starWrap">
                <div className="star"></div>
                <div className="star fill"></div>
                <div className="star fill"></div>
                <div className="star fill"></div>
                <div className="star fill"></div>
            </div>
        </div>  */}
        <button className='cta' onClick={handleSupporterDetail} style={{

          margin:'0 auto',
          marginTop:'22px'
        }}>자세히 보기</button>
      </div>
    </div>
  )

 }

export default function SupporterList() {
  
  const navigate = useNavigate();
   
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 
 
  const location = useLocation();	 
  const servicedata = location.state.servicedata;	

  const [suppoprterList, setSupporterList] = useState([])

  const initRead = async() => {
 
    let url = "https://rest.besideme.life/rest/v1/servicedata/"+servicedata.serviceSeqNum+"/supporterlist";

    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
      {
          alert(data.message);
          return;
      } 
      setSupporterList(data.result)
    })
    .catch(err => {
        alert(err.message);
    }); 
  }

  useEffect(() => {initRead()},[]);

  function handleBackClick() {  
    navigate(-1) 
  }
    
    return (
      <>
        <div className='serviceSelect bgBeige' style={{
          height:'118px',
          background:'#fff',
          paddingBottom:'5px'
        }}>
            <div className='inner32'>
              <div className='pageBack' onClick={handleBackClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"/>
                </svg>
              </div>
              <div className='pageTit' style={{
                margin:'0'
              }}>{servicedata.serviceDate}<br/>서포터를 선택해 주세요</div>
            </div>


            
        </div>
        <div className='bg'>
          {  
              suppoprterList.map((supporter, index) => ( 
    
                <SupporterItem servicedata={servicedata} data={supporter} key={index}/>
              )) 
          }
        </div>
      </>
      )

};
 