import React, { useState, useContext, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import {AppContext} from '../../App';
import NoticeMain from './NoticeMain';

function NoticeItem(props) {
  const navigate = useNavigate(); 
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 
  
  

  function handleNoticeClick() {

    navigate("../NoticeMain", { replace: false, state : {noticeID: props.noticedata.noticeID, noticeTitle: props.noticedata.noticeTitle, noticeMain: props.noticedata.noticeMain, showListButton: true}})
  }

  return (
    <tbody>
     <tr onClick={handleNoticeClick}>
         <td className='cate'>{props.noticedata.noticeCategory}</td>
         <td className='tit'> {props.noticedata.noticeTitle}</td>
         {/* NEW 또는 HOT */}
         <td><span className={(props.noticedata.noticeType==2)?"state new":(props.noticedata.noticeType==1)?"state hot":""}>{(props.noticedata.noticeType==2)?"NEW":(props.noticedata.noticeType==1)?"HOT":""}</span></td>
     </tr>
    </tbody>
  ) 

};
 

export default function NoticeList() {

  const navigate = useNavigate(); 
   
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 
 
  const [noticeList, setNoticeList] = useState([])

  const initRead = async() => {
  
    let url = "https://rest.besideme.life/rest/v1/noticelist";

    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
        {
          alert(data.message);
          return;
        }
        setNoticeList(data.result)
   
    })
    .catch(err => {
        alert(err.message);
    }); 
  }
  useEffect(() => {initRead()},[globalUserData.userSeqNum]);
 

 
  function handleBackClick() {
    navigate("/Mypage")
  }
   

return (
    <div className='noticePage'>
      <div className="subpageHeader">
        <div className="back" onClick={handleBackClick}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
        </div>
        <h2>공지사항</h2>
      </div>

      <div className='inner'>
        
        <table>
            {
              noticeList.map((notice, index) => (
                <NoticeItem  noticedata={notice} key={index}></NoticeItem>
                  ))
            }
        </table>
      </div>
    </div>
  )
};