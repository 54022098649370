import React, {useState} from "react";
import ReactModal from 'react-modal';
import "../../src/css/style.css"

function Screen4() {

    const [showRegionDialog, setShowRegionDiaglog] = useState(false)

    function handleRegionClick() {
        setShowRegionDiaglog(true)
    }
    function handleHideRegionModal() {
        setShowRegionDiaglog(false)
    }

    return (
        <>
            <div className="serviceSelect">
                <div className="pageBack"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path><path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path></svg></div>
                <div className="inner32">
                    <h1 className="pageTit" style={{
                        marginBottom:'28px'
                    }}>
                        1. 신원증명 서류 확인
                    </h1>

                    <p style={{
                        fontSize:'14px',
                        marginBottom:'25px'
                    }}>
                    서포터로 활동하기 위해 <br/>
                    신분증(주민등록증, 운전면허증)사진을<br/>
                    업로드 해 주세요.
                    </p>

                    <div className="attatchBox" onClick={handleRegionClick}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM13 13V17H11V13H7V11H11V7H13V11H17V13H13Z" fill="#222222"/>
                        </svg>
                    </div>
                    <br/>

                    <div className="attatchBox">
                        {/* 첨부된 이미지를 넣어주세요 */}
                        <img src="https://useb.co.kr/assets/images/id_card_001.png?v=1"></img>
                        <svg className="closeBtn" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM12 13.4142L8.70711 16.7071L7.29289 15.2929L10.5858 12L7.29289 8.70711L8.70711 7.29289L12 10.5858L15.2929 7.29289L16.7071 8.70711L13.4142 12L16.7071 15.2929L15.2929 16.7071L12 13.4142Z" fill="#222222"/>
                        </svg>
                    </div>

                    <ReactModal 
                        isOpen={showRegionDialog} 
                        // onRequestClose={handleHideRegionModal} 
                        >
                            <div className="modalDim" onClick={handleHideRegionModal}></div>

                            <div className="bottomPannel">
                                <button className="item">사진 촬영</button>
                                <button className="item">앨범에서 가져오기</button>
                                <button className="item" onClick={handleHideRegionModal}>취소</button>
                            </div>
                    
                    </ReactModal>
                    
                </div>

                <div className="ctaWrap bottomCta"><button className="cta">확인</button></div>
            </div>
        </>
    )
}


export default Screen4