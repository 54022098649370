import React, {useState, useEffect, useContext} from 'react';
import { useNavigate } from "react-router-dom"; 
import { AppContext } from '../../App';

const alarmChecks = [{
  text: "푸쉬 알림",  
  id: 1
},{
  text: "문자 알림",  
  id: 2
},{
  text: "이메일 알림",  
  id: 4
},{
  text: "TM 알림",  
  id: 8
}]


function AlarmSetting() {
  
  const navigate = useNavigate();
   
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 

  const initRead = async() => {

    if(globalUserData.userSeqNum == 0)
      return
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum;


    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
      {
        alert(data.message);
        return;
      }


      const copyCheckedItems = checkedItems.splice()
      copyCheckedItems[0] = ((data.result.alarmFlag & 1) > 0)
      copyCheckedItems[1] = ((data.result.alarmFlag & 2) > 0)
      copyCheckedItems[2] = ((data.result.alarmFlag & 4) > 0)
      copyCheckedItems[3] = ((data.result.alarmFlag & 8) > 0)
      setCheckedItems(copyCheckedItems)

   
    })
    .catch(err => {
        alert(err.message);
    }); 
  }
  useEffect(() => {initRead()},[globalUserData.userSeqNum]);

  function handleBackClick() {
    navigate("/", { replace: true})
  }

  const [checkedItems, setCheckedItems] = useState([false, false, false, false]);
  const handleCheck = async ({ target }) => {
    const copyCheckedItems = [checkedItems[0], checkedItems[1], checkedItems[2], checkedItems[3]]
    copyCheckedItems[target.getAttribute('index')] = !checkedItems[target.getAttribute('index')]
    setCheckedItems(copyCheckedItems)

    let alarmflag = 0
    if(copyCheckedItems[0])
      alarmflag = alarmflag + 1 
    if(copyCheckedItems[1])
      alarmflag = alarmflag + 2 
    if(copyCheckedItems[2])
      alarmflag = alarmflag + 4 
    if(copyCheckedItems[3])
      alarmflag = alarmflag + 8 
      
    
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum;
    let params = {alarmFlag : (alarmflag)}

    await fetch(url, {     
      method : "PUT",         
      body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{  
      if(data.code == "error")
        {
          alert(data.message);
          return;
        }
    })
    .catch(err => {
        alert(err.message);
    }); 
    
  };

return (
    <div className='subPage alarmSetting'>
      <div className="inner18">
        <div className="pageTit">알림 설정</div>
      </div>
      <div className='inner'>
        <div className="pageBack" onClick={handleBackClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
        </div>
        <div className="subTit">이벤트 혜택 및 마케팅 수신 동의</div>
        <p>
          알림을 켜 주시면 비사이드미의<br/> 
          다양한 혜택과 소식을 알려드립니다
        </p>
        {
         alarmChecks.map((check, index) => (
                  <div className='toggleWrap'  key={index} hidden={(index==2)}>
                    {check.text}  
                    <label className="toggleBtn">
                      <input type="checkbox" checked={checkedItems[index]} index={index} id={check.id} onChange={(e) => handleCheck(e)}></input>
                      <div className='toggleIcon'></div>
                    </label>
                  </div>
                ))
        }
      </div>
      <div className='inner inner16'>
        <ul className='guideMsg'>
            <li>정보성 알림은 위 설정값과 무관하게 받으실 수 있습니다</li>
            <li>알림을 켰는데도 푸시 알림이 오지 않는다면,<br/>기기 설정에서 ‘알림사용’을 켜 주세요</li>
        </ul>
      </div>

    </div>
  )
};

export default AlarmSetting;