import React, {useState} from "react";
import { useNavigate, useLocation } from "react-router-dom"; 
import ReactModal from 'react-modal';
import "../../../src/css/style.css"

function SelectedServiceDetail() {
 
    const navigate = useNavigate(); 

    function handleBackClick() { 
        navigate(-1)
    }

    return (
        <>
            <div className='topWrap'>
                <div className="subpageHeader" style={{
                    height:'120px'
                }}>
                    <div className="back" onClick={handleBackClick}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"/>
                        </svg>
                    </div>
                    <h2>
                        7월 17일(수) <br/>
                        완료내역
                    </h2>
                </div>
          </div>
          <div className="bg">
            <div className="card">
                <div>
                    <div className='item'>
                        <div className='key'>요청 내용 </div>
                        <div className='value'>취미·교육 {'>'} 미술</div>
                    </div>
                    <div className='flexWrap'>
                        <div className='item'>
                        <div className="key">요청 일자 </div>
                        <div className='value'>7월 17일(수)</div>
                        </div>
                        <div className='item'>
                        <div className="key">요청 일자 </div>
                        <div className='value'>9:00 ~ 11:00</div>
                        </div>
                    </div>
            
                </div>
                <div>
                    <div className="section" style={{
                        padding: '20px'
                    }}>
                        {/* "추가 결제 요청" 없는 케이스에서는 삭제 */}
                        <div className="blackBtn">추가 결제 요청</div>
                        <div className='item'>
                            <div className="value">결제 정보</div>
                            <div className="between">
                                <p>상품</p>
                                <p>미술 2시간</p>
                            </div>
                            <div className="between">
                                <p>결제액</p>
                                <p>30,000 원</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="section" style={{
                        padding: '20px'
                    }}>
                        <div className='item'>
                            <div className="value">결제 정보</div>
                            <div className="between">
                                <p>상품</p>
                                <p>미술 2시간</p>
                            </div>
                            <div className="between">
                                <p>결제액</p>
                                <p>27,000 원</p>
                            </div>
                            <div className="between">
                                <p>정산일</p>
                                <p>정산 예정</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ctaWrap bottomCta"><button className="cta" onClick={handleBackClick}>확인</button></div>
            </div>
          </div>
        </>
    )
}


export default SelectedServiceDetail