import React, {useState, useEffect, useContext, useRef} from 'react';
import { useNavigate, useLocation, useRoutes } from "react-router-dom"; 
import { AppContext } from '../../App';
import AlarmSetting from './AlarmSetting';
import ServiceDataShortlView from '../componentsUsage/ServiceDataShortView';


export default function ServiceRequestSupport() {
  
  const navigate = useNavigate()
  const {globalUserData, setGlobalUserData} = useContext(AppContext)

  const [confirmed, setConfirmed] = useState(false)
  const [priceChecker, setPriceChecker] = useState([false, true, false, false, false, false, false, false, false, false])
  const [price, setPrice] = useState(15000)

  const reasonRef = useRef("")
  const refRef = useRef("") 

  const location = useLocation()
  const servicedata = location.state.service


  function handleBackClick() {  
    navigate(-1) 
  } 

  function handleAlarmClick() {
    navigate("/Home/AlarmSetting", { replace: true})
  }

  async function handleSupportClick() {  
   
    let url = "https://rest.besideme.life/rest/v1/users/"+globalUserData.userSeqNum+"/servicesupportlist";
    let params = {serviceSeqNum: (servicedata.serviceSeqNum),
                  price: price, reason: (reasonRef.current.value), refer: (refRef.current.value), duration: (servicedata.serviceDuration)}

    await fetch(url, {
      method : "POST",         
      body: JSON.stringify(params)    
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{  
      if(data.code == "error")
        {
          alert(data.message);
          return;
        }
       setConfirmed(true)
    })
    .catch(err => {
        alert(err.message);
    }); 
 
  }

  function handlePriceSelectClick(price, flag) {
    setPrice(price)
    let priceChecker_ = new Array(10).fill(false)
    priceChecker_[flag] = true
    setPriceChecker(priceChecker_)

  }

  if(confirmed)
  {
    return (

      <div className='subPage serviceSelect ServiceRequestSupport'>
        <div className="inner32">
          <div className="pageTit">서포트 신청이 완료되었습니다.</div>
        </div>
      
        <div className='inner'> 
          <div className="pageBack" onClick={handleBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
          </div>

          <div className='card' style={{
            marginTop:'40px',
            paddingBottom:'60px'
            }}>
            <ServiceDataShortlView data={servicedata} canModify={false}></ServiceDataShortlView>
            <div className='section'> 
                <p>            
                서포터 신청 후 도움을 요청하신 분꼐서<br/>
                서포터를 선택하시고, 결제를 완료하시면<br/>
                서포터 활동이 진행됩니다.<br/>
                <br/>
                결제가 완료된 도움리스트는<br/> 
                서포터되기 &gt; 이용현황에서<br/>
                확인 가능합니다.<br/>
                <br/>
                알림설정 푸시알림을 켜 놓으시면<br/> 
                푸시 알림으로도 결제내역을<br/> 
                알려드립니다.<br/> 
                <br/> 
                </p> 
            </div>

            <button className='goldBtn' style={{
              display:'inline-flex',
              width:'93px',
              height:'27px',
              borderRadius:'0',
              color:'#000',
              fontSize:'12px',
              fontWeight:'normal'
            }} onClick={handleAlarmClick}>알림 확인하기</button> 
          
          </div>

          <button className='cta' style={{
            marginTop:'35px'
          }} onClick={handleBackClick}>확인</button> 
 
        </div>
       </div>

      )
  }
  else 
  {
    return (
        <div className='subPage serviceSelect'>
          <div className='pageBack' onClick={handleBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
          </div>
          <div className='inner32'>
            <h1 className="pageTit" style={{marginBottom: '30px'}}>{servicedata.userName}님 서포트하기</h1>
          </div>
          <div className='bg' style={{padding:'30px', background:'#fff'}}>
              <div className='inputWrapTit'>신청 사유</div>
              <textarea className='borderTextArea' ref={reasonRef} rows="5" placeholder="ex. 한국대학교 조소화 휴학생이고, 외할머니와 함께 살아서 어르신과 소통이 잘 되고 어르신을 잘 따릅니다 (최대 50자)"></textarea>
              <div className='inputWrapTit' style={{marginTop:'40px'}}>서포트 비용 (시간당)</div>
              <div className='radio2' style={{marginTop:'13px'}}>
                <div className={priceChecker[0]?"item fill":"item"} onClick={() => handlePriceSelectClick(10000, 0)}>10,000원</div>
                <div className={priceChecker[1]?"item fill":"item"} onClick={() => handlePriceSelectClick(15000, 1)}>15,000원</div>
                <div className={priceChecker[2]?"item fill":"item"} onClick={() => handlePriceSelectClick(20000, 2)}>20,000원</div>
                <div className={priceChecker[3]?"item fill":"item"} onClick={() => handlePriceSelectClick(25000, 3)}>25,000원</div>
                <div className={priceChecker[4]?"item fill":"item"} onClick={() => handlePriceSelectClick(30000, 4)}>30,000원</div>
                <div className={priceChecker[5]?"item fill":"item"} onClick={() => handlePriceSelectClick(35000, 5)}>35,000원</div>
                <div className={priceChecker[6]?"item fill":"item"} onClick={() => handlePriceSelectClick(40000, 6)}>40,000원</div>
                <div className={priceChecker[7]?"item fill":"item"} onClick={() => handlePriceSelectClick(45000, 7)}>45,000원</div>
                <div className={priceChecker[8]?"item fill":"item"} onClick={() => handlePriceSelectClick(50000, 8)}>50,000원</div>
                <div className={priceChecker[9]?"item fill":"item"} onClick={() => handlePriceSelectClick(55000, 9)}>55,000원</div>
              </div>
              <div className='inputWrapTit' style={{marginTop:'40px'}}>참고해 주세요</div>
              <textarea className='borderTextArea' style={{height:'300px'}} ref={refRef} rows="5" placeholder="비용 책정 기준 및 서포트에 필요한 준비물 등 안내 사항을 기재해 주세요.&#13;&#10; &zwnj;  &#13;&#10; &#13;&#10;ex) 미술 재료비 포함하여 서비스 비용을 책정했습니다.&#13;&#10;&#13;&#10;추가 준비물 및 비용은 없습니다. 
&#13;&#10;&#13;&#10;당일 물감이 옷에 묻을 수 있으니 앞치마를 착용하시길 권장 드립니다. &#13;&#10;&#13;&#10;감사합니다"></textarea>
          </div>
          <div className='goldBox'>
            <button className='cta' onClick={handleSupportClick}>확인</button>
          </div>
        </div>
        )
  }
};