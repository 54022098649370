import React, {useState} from "react";
import ReactModal from 'react-modal';
import "../../src/css/style.css"

function Screen7() {

    const [showRegionDialog, setShowRegionDiaglog] = useState(false)

    function handleRegionClick() {
        setShowRegionDiaglog(true)
    }
    function handleHideRegionModal() {
        setShowRegionDiaglog(false)
    }

    return (
        <>
            <div className="serviceSelect">
                <div className="pageBack"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path><path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path></svg></div>
                <div className="inner32">
                    <h1 className="pageTit" style={{
                        marginBottom:'28px'
                    }}>
                        3. 범죄이력 확인
                    </h1>

                    <p style={{
                        fontSize:'14px',
                        marginBottom:'20px'
                    }}>
                    서포터는 도움을 요청하는 장소에<br/>
                    방문을 하고, 함께 동행을 하기 때문에<br/>
                    신뢰와 안전이 기본이 되어야 합니다<br/><br/>
                    이에, 비사이드미에서는 <br/>
                    서포터의 범죄 이력을 확인하고 있습니다.<br/><br/>
                    범죄경력회보서 발급시스템<br/>
                    crims.police.go.kr 에서 ‘범죄경력회보서’를<br/>
                    발급 받으시고<br/>
                    support@besideme.life로 보내주세요.<br/><br/>
                    본인의 범죄경력회보서를 <br/>
                    비사이드미에 제공하는 것에 동의하시면<br/>
                    ‘네 동의합니다’ 버튼을 클릭해 주세요<br/><br/>
                    미동의 시 ‘공식 인증 서포터’ 인증이 어렵습니다.<br/>
                    </p>
                    
                </div>

                <div className="bottomCta" style={{
                    marginBlock:'30px',
                    padding:'0 12px'
                }}>
                    <button className="cta" style={{
                        marginBottom:'13px'
                    }}>네 동의합니다</button>
                    <button className="goldBtn" onClick={handleRegionClick} style={{
                        height:'45px'
                    }}>아니요 동의하지 않습니다</button>
                </div>

                <ReactModal 
                    isOpen={showRegionDialog} 
                    onRequestClose={handleHideRegionModal} 
                    shouldCloseOnOverlayClick={false}
                    >
                        <div className="modalDim"></div>

                        <div className="confirm">
                            <div className="desc">
                                범죄경력회보서를<br/>
                                비사이미에 제공하는 것에<br/>
                                동의하지 않을 경우<br/>
                                ‘공식인증 서포터’ 인증이 어렵습니다.<br/><br/>
                                정말 동의하지 않으시겠습니까?<br/>
                            </div>
                            <div className="btnWrap">
                                <button className="primary" onClick={handleHideRegionModal}>동의 합니다</button>
                                <button className="primary">동의하지 않습니다</button>
                            </div>
                        </div>
                    
                </ReactModal>
            </div>
        </>
    )
}


export default Screen7