import React from "react";
import { useNavigate } from "react-router-dom"; 
import "../../../src/css/style.css"

function CertifiedSupporterBenefit() {
 
    const navigate = useNavigate(); 


    function handleBackClick() {
      navigate(-1)
    }
     
    function handleBenefitCllick() {
      navigate("/Mypage/CertifiedSupporterBenefit", { replace: false})
    }

    return (
        <>
            <div className="serviceSelect">
                <div className="pageBack" onClick={handleBackClick}><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path><path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path></svg></div>
                <div className="inner32">
                    <h1 className="pageTit" style={{
                        marginBottom:'50px'
                    }}>
                        비사이드미 공식 인증<br/>
                        서포터가 되면<br/>
                        이런 혜택이 있어요!
                    </h1>

                    <div style={{
                        position:'relative'
                    }}>
                        <h3 style={{
                            marginBottom:'9px',
                            fontSize:'16px',
                            fontWeight:'500'
                        }}>1. 인증 뱃지 부착</h3>
                        <p style={{
                            fontSize:'14px',
                            marginBottom:'34px',
                            paddingLeft:'18px'
                        }}>
                            프로필 사진에 녹색 테두리 및<br/>
                            이름 옆에 인증 뱃지를 <br/>
                            부착해 드립니다!
                        </p>
                        <div style={{
                            position:'absolute',
                            top:0,
                            right:0,
                        }}>
                            <svg width="67" height="70" viewBox="0 0 67 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="30" cy="30" r="30" fill="#EEECEC"/>
                            <circle cx="30" cy="30" r="27.5" fill="white" stroke="#45D88E" stroke-width="5"/>
                            <circle cx="30" cy="29" r="16" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                            <path d="M23.5719 34.7559C24.2986 35.3853 25.2844 35.8619 26.3894 36.1809C27.5022 36.5021 28.7461 36.6667 30 36.6667C31.254 36.6667 32.4979 36.5021 33.6107 36.1809C34.7157 35.8619 35.7015 35.3853 36.4282 34.7559" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                            <circle cx="25" cy="25.6667" r="1.91667" fill="#33363F" stroke="#33363F" stroke-width="0.5" stroke-linecap="round"/>
                            <circle cx="35" cy="25.6667" r="1.91667" fill="#33363F" stroke="#33363F" stroke-width="0.5" stroke-linecap="round"/>
                            <path d="M60.6072 46.0793L59.615 45.9552L60.6072 46.0793C60.7178 45.1948 60.2297 44.344 59.4105 43.9929L50.2878 40.0832C49.7847 39.8676 49.2152 39.8676 48.7121 40.0832L39.5894 43.9929C38.7702 44.344 38.2821 45.1948 38.3927 46.0793L39.464 54.6498C39.6842 56.411 40.5651 58.0228 41.9287 59.1591L48.2196 64.4016C48.9613 65.0196 50.0386 65.0196 50.7803 64.4016L57.0713 59.1591C58.4348 58.0228 59.3158 56.411 59.5359 54.6498L60.6072 46.0793Z" fill="#45D88E" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                            <path d="M45.125 52.5L49.0688 56.4437C49.2919 56.6668 49.6633 56.6301 49.8384 56.3675L55.3334 48.125" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                            </svg>
                        </div>
                    </div>

                    <h3 style={{
                        marginBottom:'9px',
                        fontSize:'16px',
                        fontWeight:'500'
                    }}>2. 서포터 선택 시 상위 노출</h3>
                    <p style={{
                        fontSize:'14px',
                        marginBottom:'34px',
                        paddingLeft:'18px'
                    }}>
                        비사이드미 공식 인증 서포터가 되면<br/>
                        서포터 선택 시 상위에 노출됨으로써<br/>
                        서포터의 기회를 더 빨리 더 많이 <br/>
                        얻을 수 있어요!
                    </p>
                    <h3 style={{
                        marginBottom:'9px',
                        fontSize:'16px',
                        fontWeight:'500'
                    }}>3. 공식 인증 유니폼 지급</h3>
                    <p style={{
                        fontSize:'14px',
                        marginBottom:'34px',
                        paddingLeft:'18px'
                    }}>
                        서포트 진행 시 <br/>
                        비사이드미 공식 인증 유니폼을 착용하고 <br/>
                        진행함으로써 신뢰도를 높일 수 있으며,<br/>
                        회사가 준비한 추가 혜택 들도 <br/>
                        받으실 수 있습니다! 
                    </p>
                    
                </div>


                <div className="ctaWrap bottomCta"><button className="cta" onClick={handleBackClick}>확인</button></div>

            </div>
        </>
    )
}


export default CertifiedSupporterBenefit