import * as moment from 'moment';
import React, {useState, useEffect, useRef} from "react";
import ReactModal from 'react-modal';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import {format} from 'date-fns'; 

const styleCancelButton = {
                        marginTop: "13px",
                        height: "45px",
                        flexBasis: "auto",
                        backgroundColor: "#f1eee1",
                        border: "0",
                        width: "calc(100% - 40px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
}

const styleModifyButton = {
                        marginTop: "13px",
                        height: "45px",
                        flexBasis: "auto",
                        backgroundColor: "#45D88E",
                        border: "0",
                        width: "calc(100% - 40px)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "14px",
                        fontWeight: "bold",
                    }

const styleTable2 = {
                    margin: "18px 0",
                    tableLayout: "fixed",
                    width: "100%"
                    }

const styleTable2Th = {
                    color: "#000",
                    fontFamily: "Spoqa Han Sans Neo",
                    fontSize: "16px",
                    fontStyle: "normal",
                    lineHeight: "1.6",
                    verticalAlign: "middle",
                    fontWeight: "700",
                    paddingLeft: "6px",
                    width: "87px"
                    }

const styleBtn1 = {
                      color: "#BAB288",
                      textAlign: "center",
                      fontFamily: "Spoqa Han Sans Neo",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "700",
                      lineHeight: "1.6;",
                      display: "flex",
                      alignItems: "center",
                      padding: "0 12px",
                      minWidth: "50px",
                      width: "auto",
                      height: "40px",
                      backgroundColor: "#F1EEE1",
                      border: "0",
                      borderRadius: "10px"
                  }

const stylePickTime = {
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "12px 15px",
                  justifyContent: "center",
                  marginTop: "18px"
                  }

const stylePickTimeDiv = {
                  alignItems: "center",
                  border: "1px solid #bab288",
                  boxSizing: "border-box",
                  display: "flex",
                  flex: "1 0 1fr",
                  fontSize: "14px",
                  height: "28px",
                  justifyContent: "center",
                  minWidth: "25%",
                  textAlign: "center"
                  }

const styleLabel = {
                  alignItems: "center",
                  display: "flex",
                  fontSize: "14px",
                  gap: "4px",
                  justifyContent: "space-between",
                  marginBottom: "14px"
                  }

const styleToggleBtnWrap = {
                  display: "flex",
                  gap: "18px",
                  marginBottom: "40px",
                  width: "100%"
                  }

const styleToggleBtnWrapBtn = {
                    alignItems: "center",
                    backgroundColor: "#f1eee1",
                    border: "0",
                    borderRadius: "10px",
                    color: "#bab288",
                    display: "flex",
                    flex: "1 0",
                    fontSize: "14px",
                    fontWeight: "700",
                    height: "40px",
                    width:"auto",
                    minWidth: "50px",
                    justifyContent: "center"
                    }
const styleToggleBtnWrapBtnOn = {
                      alignItems: "center",
                      backgroundColor: "#45d88e",
                      border: "0",
                      borderRadius: "10px",
                      color: "#000",
                      display: "flex",
                      flex: "1 0",
                      fontSize: "14px",
                      fontWeight: "700",
                      height: "40px",
                      width:"auto",
                      minWidth: "50px",
                      justifyContent: "center"
                      }

function TimeListModal(props) {

  const handleTimeSelectClick = (time) => {  
    props.callback(time) 
  }
  return (
    <div style={stylePickTime}>
        <div style={stylePickTimeDiv} onClick={() => handleTimeSelectClick("8:00")}>8:00</div>
        <div style={stylePickTimeDiv} /*className='ac'*/ onClick={() => handleTimeSelectClick("9:00")}>9:00</div>
        <div style={stylePickTimeDiv} onClick={() => handleTimeSelectClick("10:00")}>10:00</div>
        <div style={stylePickTimeDiv} onClick={() => handleTimeSelectClick("11:00")}>11:00</div>
        <div style={stylePickTimeDiv} onClick={() => handleTimeSelectClick("12:00")}>12:00</div>
        <div style={stylePickTimeDiv} onClick={() => handleTimeSelectClick("13:00")}>13:00</div>
        <div style={stylePickTimeDiv} onClick={() => handleTimeSelectClick("14:00")}>14:00</div>
        <div style={stylePickTimeDiv} onClick={() => handleTimeSelectClick("15:00")}>15:00</div>
        <div style={stylePickTimeDiv} onClick={() => handleTimeSelectClick("16:00")}>16:00</div>
    </div>
  )
}


function DurationListModal(props) {

  const handleDurationSelectClick = (duration) => {  
    props.callback(duration) 
  }
  return (
    <div>
      <div style={stylePickTime}>
        <div style={stylePickTimeDiv} onClick={() => handleDurationSelectClick("1시간")}>1시간</div>
        <div style={stylePickTimeDiv} /*className='ac'*/ onClick={() => handleDurationSelectClick("2시간")}>2시간</div>
        <div style={stylePickTimeDiv} onClick={() => handleDurationSelectClick("3시간")}>3시간</div>
        <div style={stylePickTimeDiv} onClick={() => handleDurationSelectClick("4시간")}>4시간</div>
        <div style={stylePickTimeDiv} onClick={() => handleDurationSelectClick("5시간")}>5시간</div>
        <div style={stylePickTimeDiv} onClick={() => handleDurationSelectClick("6시간")}>6시간</div> 
        <div style={stylePickTimeDiv} onClick={() => handleDurationSelectClick("12시간")}>12시간</div>
        <div style={stylePickTimeDiv} onClick={() => handleDurationSelectClick("24시간")}>24시간</div>
        <div style={stylePickTimeDiv} onClick={() => handleDurationSelectClick("36시간")}>36시간</div>
      </div>
    </div>
  )
}


export default function ServiceDataShortlView(props) {

  const [showDateDialog, setShowDateDialog] = useState(false)

 
  const [serviceDate, setServiceDate] = useState("")
  const [serviceTime, setServiceTime] = useState("")
  const [serviceDuration, setServiceDuration] = useState("")


  const [serviceDateMain, setServiceDateMain] = useState("")
  const [serviceTimeMain, setServiceTimeMain] = useState("")
  const [serviceDurationMain, setServiceDurationMain] = useState("")


  const [showCalendar, setShowCalendar] = useState(false)
  const [showTime, setShowTime] = useState(false)
  const [showDuration, setShowDuration] = useState(false)

  const [calendarValue, setCalendarValue] = useState(new Date())

  const [serviceData, setServiceData] = useState({serviceTitle:"", serviceDate:"", serviceTime:"",serviceDuration:""})

  var mindate = new Date();
  mindate.setDate(mindate.getDate() + 1);

  useEffect(() => {
    setServiceData(props.data)
    setServiceDate(props.data.serviceDate)
    setServiceTime(props.data.serviceTime)
    setServiceDuration(props.data.serviceDuration)


    setServiceDateMain(props.data.serviceDate)
    setServiceTimeMain(props.data.serviceTime)
    setServiceDurationMain(props.data.serviceDuration)

  }, [])

  function init_setting() { 

    setServiceDate(serviceDateMain)
    setServiceTime(serviceTimeMain)
    setServiceDuration(serviceDurationMain)
  }

  function handleDateSelectClick() {

    setShowCalendar(true);
    setShowTime(false)
    setShowDuration(false)
  }

  function handleCalendarChange(date) {
 
    setServiceDate(format(date, 'yyyy년 MM월 dd일'))

    setShowCalendar(false)
 
  }
 
  function handleTimeSelectClick() {

    setShowCalendar(false);
    setShowTime(true)
    setShowDuration(false)
 
  }

  function handleTimeChange(time) { 
    setShowTime(false)
    setServiceTime(time)
 
  }

  function handleDurationSelectClick() {

    setShowCalendar(false);
    setShowTime(false)
    setShowDuration(true)
 
  }

  function handleDurationChange(duration) {
    setShowDuration(false)
    setServiceDuration(duration)
 

  }

  function handleModifyDate() {
     
      setShowDateDialog(true)
  }

  function handleHideModal() {
      setShowDateDialog(false)
    
  }
  function handleHideModalCancel() {
      init_setting()
      handleHideModal()
  }

  async function handleModifyGroup1Modal() {


    let url = "https://rest.besideme.life/rest/v1/servicedata/"+props.data.serviceSeqNum
    let params = {serviceDate : serviceDate, serviceTime: serviceTime, serviceDuration:serviceDuration}

    await fetch(url, {     
      method : "PUT",         
      body: JSON.stringify(params)   
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{  
      if(data.code == "error")
        {
          alert(data.message);
          return;
        }


      setServiceDateMain(serviceDate)
      setServiceTimeMain(serviceTime)
      setServiceDurationMain(serviceDuration)
  

      handleHideModal()
    })
    .catch(err => {
        alert(err.message);
    }); 

 
  }

    let bTerminated = false
    let bMorePayment = false
    let bNormal = false
    let normalText = ""
    let bConfirm = false

    if(props.data.status == 0)
    {

      const now = moment()
      const startDate = moment(now.format("YYYY-MM-DD"), "YYYY-MM-DD")
      const endDate = moment(props.data.serviceDate, "YYYY년 MM월 DD일")
      const daysDifference = endDate.diff(startDate, 'days');

      if(daysDifference<0)
      {
        bTerminated = true
        bMorePayment = false
        bNormal = false
        bConfirm = false
      }
      else
      {
        bTerminated = false
        bMorePayment = false
        bNormal = true
        bConfirm = false

        normalText = "D-"+(daysDifference)
      }
        
    }
    else if(props.data.status == 1) 
    {       
        bTerminated = false
        bMorePayment = false
        bNormal = false
        bConfirm = true 
    }
    else if(props.data.status == 5) 
    {
        bTerminated = true
        bMorePayment = false
        bNormal = false
        bConfirm = false
    } 

    function handleModifyDate() {
        setShowDateDialog(true)
    }

    let isHidden = !props.canModify

    return (
      <div>
          <div className='absol'>
            <div className='badgeCircle' hidden={!bTerminated}>종료</div>
            <div className='badgeCircle'hidden={!bMorePayment}>추가<br/>결제</div>
            <div className='badgeCircle d-day' hidden={!bNormal}>{normalText}</div>
            <div className='badgeCircle confirm' hidden={!bConfirm}>확정</div>
          </div>
          <div className='item'>
            <div className='key'>요청 내용 </div>
            <div className='value'>{serviceData.serviceTitle} </div>
          </div>
          <div className='flexWrap'>
          <button className='modify' hidden={isHidden} onClick={handleModifyDate} style={{position:"absolute", right: "11px",
                          width: "36px", height: "15px", backgroundColor: "transparent" ,border: "0", fontSize: "11px", fontWeight: "300"}}>수정</button> 
            <div className='item'>
              <div className="key">요청 일자 </div>
              <div className='value'>{serviceDateMain}</div>
            </div>
            <div className='item'> 
            </div>
            <div className='item'>
              <div className='key'>요청 시간</div>
              <div className='value'>{serviceTimeMain}</div>
            </div>
            <div className='item'>
              <div className='key'>소요 시간 </div>
              <div className='value'>{serviceDurationMain}</div>
            </div>
          </div>
   
          <ReactModal 
              ariaHideApp={false}
              isOpen={showDateDialog} 
              onRequestClose={handleHideModal} 
              shouldCloseOnOverlayClick={true}
              style={{
                overlay: {
                  backgroundColor: " rgba(0, 0, 0, 0.4)",
                  width: "100%",
                  height: "150vh",
                  zIndex: "10",
                  position: "fixed",
                  top: "0",
                  left: "0",
                },
                content: {
                  width: "80%",
                  height: "750px",
                  zIndex: "150",
                  position: "absolute",
                  top: "20%",
                  left: "10%",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  justifyContent: "center",
                  overflow: "auto",
                  margin: '10%'
                },
              }}> 

          <div className='inner'>
            <table style={styleTable2}>
              <tr>
                <th style={styleTable2Th}>요청 일자</th>
                <td>{serviceDate}</td>
                <td>
                  <button style={styleBtn1} onClick={handleDateSelectClick}>선택</button>
                </td>
              </tr>
            </table>
          </div>
          <div className='calendar' style={{display:showCalendar?"block":"none"}}>
            <Calendar 
              minDetail="month" 
              next2Label={null} 
              prev2Label={null} 
              maxDetail="month" 
              showNavigation="false" 
              locale="ko" 
              minDate={mindate}
              onChange={handleCalendarChange} 
              value={calendarValue}
            />
          </div>

          <div className='inner'>
            <table style={styleTable2}>
              <tr>
                <th style={styleTable2Th}>요청 시간</th>
                <td>{serviceTime}</td>
                <td>
                  <button style={styleBtn1} onClick={handleTimeSelectClick}>선택</button>
                </td>
              </tr>
            </table>
          </div>

          <div style={{display:showTime?"block":"none"}}>
            <TimeListModal callback={handleTimeChange}/>
          </div>

          <div className='inner'>
            <table style={styleTable2}>
              <tr>
                <th style={styleTable2Th}>소요 시간</th>
                <td>{serviceDuration}</td>
                <td>
                  <button style={styleBtn1} onClick={handleDurationSelectClick}>선택</button>
                </td>
              </tr>
            </table>
          </div>
          <div style={{display:showDuration?"block":"none"}}>
              <DurationListModal callback={handleDurationChange}/>
          </div>
          <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                      marginBottom: "6px",
                      bottom: "20px",
                      position:"absolute",
                      width:"calc(100% - 20px)"
                    }}>
                    <button style={styleCancelButton} onClick={handleHideModalCancel}>취소</button>  
                    <button style={styleModifyButton} onClick={handleModifyGroup1Modal}>수정</button> 
                  </div>
 
              
            </ReactModal>
      </div>
    )
  }

  export  function ServiceDataSecondShortlView(props) {

    const [petExist, setPetExist] = useState(false)
    const [cctvExist, setCctvExist] = useState(false)
    const [radioChecked, setRadioChecked] = useState([true, false, false])


    const [petExistMain, setPetExistMain] = useState(false)
    const [cctvExistMain, setCctvExistMain] = useState(false)
  
    const [serviceData, setServiceData] = useState({petExist:0, cctvExist:0})

    const [helpDetail, setHelpDetail] = useState("")
    const [additionalDetail, setAdditionalDetail] = useState("")

    const [helpDetailMain, setHelpDetailMain] = useState("")
    const [additionalDetailMain, setAdditionalDetailMain] = useState("")

    const [enterInfo, setEnterInfo] = useState("")

    let entranceDetail = "오시면 초인종을 눌러주세요."
    if(props.data.entranceIndex == 1)
      entranceDetail = "오시면 전화 주세요"
    else if(props.data.entranceIndex == 2)
      entranceDetail = props.data.entranceDetail

      const [showRegionDialog, setShowRegionDiaglog] = useState(false)


      const [showAddressDialog, setShowAddressDiaglog] = useState(false)
      const [showInfoDialog, setShowInfoDiaglog] = useState(false)
      const [showAdditionalDialog, setShowAdditionalDiaglog] = useState(false)


      useEffect(() => {
        setServiceData(props.data)
        setPetExist((props.data.petExist==1))
        setCctvExist((props.data.cctvExist==1))
        setPetExistMain((props.data.petExist==1))
        setCctvExistMain((props.data.cctvExist==1))

        let _radioChecked = [false, false, false]
        _radioChecked[props.data.entranceIndex] = true
        setRadioChecked(_radioChecked)

        setHelpDetail(props.data.helpDetail)
        setAdditionalDetail(props.data.additionalDetail)

        setHelpDetailMain(props.data.helpDetail)
        setAdditionalDetailMain(props.data.additionalDetail)

        setEnterInfo(entranceDetail)

      }, [])

      function init_setting() {

        setPetExist(petExistMain)
        setCctvExist(cctvExistMain) 
 
        setHelpDetail(helpDetailMain)
        setAdditionalDetail(additionalDetailMain)

      }
      
      function handleRadioChange(radioIndex) 
      {  
        let radioChecked_ = new Array(3).fill(false)
        radioChecked_[radioIndex] = true
        setRadioChecked(radioChecked_)
    
      }
      
      function handleHideRegionModal() {
          setShowRegionDiaglog(true)
      }
      function handleHideModal() {
          setShowRegionDiaglog(false)
      }

      function handleModifyAddress() {
          init_setting()
          setShowAddressDiaglog(true)
      }

      function handleModifyInfo() {
          init_setting()        
          setShowInfoDiaglog(true)
      }

      function handleModifyAdditional() {
          init_setting()
          setShowAdditionalDiaglog(true)
      }

      function handleHideModal() {
          setShowAddressDiaglog(false)
          setShowInfoDiaglog(false)
          setShowAdditionalDiaglog(false)
      }
      
      function handleHideModalCancel() {
          init_setting()
          handleHideModal()
      }

      async function handleModifyGroup2Modal() {
          handleHideModal()
      }

      async function handleModifyGroup3Modal() {

        let pExist = 0
        if(petExist) 
          pExist = 1

        let cExist = 0
        if(cctvExist) 
          cExist = 1

        let entranceIndex = 0
        if(radioChecked[1])
          entranceIndex = 1
        else if(radioChecked[2])
          entranceIndex = 2
        let url = "https://rest.besideme.life/rest/v1/servicedata/"+props.data.serviceSeqNum
        let params = {petExist : pExist, cctvExist: cExist, entranceIndex: entranceIndex, entranceDetail: enterInfo}
    
        await fetch(url, {     
          method : "PUT",         
          body: JSON.stringify(params)   
        })
        .then(response=>{  
          return response.json();
        })
        .then(data=>{  
          if(data.code == "error")
            {
              alert(data.message);
              return;
            }

          let entranceDetail = "오시면 초인종을 눌러주세요."
          if(entranceIndex == 1)
            entranceDetail = "오시면 전화 주세요"
          else if(entranceIndex == 2)
            entranceDetail = enterInfo
      
          setEnterInfo(entranceDetail)

          setPetExistMain(petExist)
          setCctvExistMain(cctvExist)

            handleHideModal()
        })
        .catch(err => {
            alert(err.message);
        }); 
     
      }

      async function handleModifyGroup4Modal() {

        let url = "https://rest.besideme.life/rest/v1/servicedata/"+props.data.serviceSeqNum
        let params = {helpDetail : helpDetail, additionalDetail: additionalDetail}
    
        await fetch(url, {     
          method : "PUT",         
          body: JSON.stringify(params)   
        })
        .then(response=>{  
          return response.json();
        })
        .then(data=>{  
          if(data.code == "error")
            {
              alert(data.message);
              return;
            }


          setHelpDetailMain(helpDetail)
          setAdditionalDetailMain(additionalDetail)

            handleHideModal()
        })
        .catch(err => {
            alert(err.message);
        }); 
    


      }

      let isHidden = !props.canModify
    return (
 
          <div>
            <div className='section'>
              <button className='modify' /*hidden={isHidden}*/ hidden={true} onClick={handleModifyAddress}>수정</button>
              <div className="value">방문장소</div>
              {/* <div className='viewLocation'>위치보기</div> */}
              <p>{serviceData.addr}</p>
              <p>{serviceData.addrDetail}</p>
            </div>
            <div className="section">
              <button className='modify' hidden={isHidden} onClick={handleModifyInfo}>수정</button>
              <div className='item'>
                <div className="value">출입방법</div>
                <p>{enterInfo}</p>
              </div>
              <div className='item'>
                <div className="value">반려동물</div>
                <p>{petExistMain?"있음":"없음"}</p>
              </div>
              <div className='item'>
                <div className="value">CCTV</div>
                <p>{cctvExistMain?"있음":"없음"}</p>
              </div>
            </div>
            <div className='section'>
              <button className='modify' hidden={isHidden}  onClick={handleModifyAdditional}>수정</button>
              <div className="item">
                <div className="value">도움이 필요한 내용</div>
                <pre style={{whiteSpace: "pre-wrap"}}>{helpDetailMain}</pre>
              </div>
              <div className="item">
                <div className="value">추가 안내 사항</div>
                <pre style={{whiteSpace: "pre-wrap"}}>{additionalDetailMain}</pre>
              </div>
            </div>

            <ReactModal 
              ariaHideApp={false}
              isOpen={showRegionDialog} 
              onRequestClose={handleHideRegionModal} 
              shouldCloseOnOverlayClick={false}
              style={{
                overlay: {
                  backgroundColor: " rgba(0, 0, 0, 0.4)",
                  width: "100%",
                  height: "100vh",
                  zIndex: "10",
                  position: "fixed",
                  top: "0",
                  left: "0",
                },
                content: {
                  width: "80%",
                  height: "400px",
                  zIndex: "150",
                  position: "absolute",
                  top: "20%",
                  left: "10%",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  justifyContent: "center",
                  overflow: "auto",
                  margin: '10%'
                },
              }}>
                <div className="modalDim"></div>

                <div className="confirm alert">
                    <div className="desc">
                      수정이 완료되었습니다.
                    </div>
                    <div className="btnWrap">
                        <button className="primary" style={{
                          width: '70px',
                          flex: '0 0 auto',
                          margin: '0 auto'
                        }} onClick={handleHideRegionModal}>확인</button>
                    </div>
                </div>
              
            </ReactModal>


            <ReactModal 
              ariaHideApp={false}
              isOpen={showAddressDialog} 
              onRequestClose={handleHideModal} 
              shouldCloseOnOverlayClick={true}
              style={{
                overlay: {
                  backgroundColor: " rgba(0, 0, 0, 0.4)",
                  width: "100%",
                  height: "100vh",
                  zIndex: "10",
                  position: "fixed",
                  top: "0",
                  left: "0",
                },
                content: {
                  width: "80%",
                  height: "400px",
                  zIndex: "150",
                  position: "absolute",
                  top: "20%",
                  left: "10%",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  justifyContent: "center",
                  overflow: "auto",
                  margin: '10%'
                },
              }}>
                <div>

{/*
                  <div className='inner'>
                    <AddressInfo pageData = {pageData} addrIndex={addrIndex}/> 
                    <div className="ctaWrap bottomCta">
                      <button className={'cta ' + (!nextEnabled?'disabled':'')}disabled={!nextEnabled} onClick={handleNextClicked}>다음</button>
                    </div>
                  </div>
   */}               

                <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                      marginTop: "6px",
                      bottom: "20px",
                      position:"absolute",
                      width:"calc(100% - 20px)"
                    }}>
                    <button style={styleCancelButton} onClick={handleHideModalCancel}>취소</button>  
                    <button style={styleModifyButton} onClick={handleModifyGroup2Modal}>수정</button> 
                  </div>

              </div>
              
            </ReactModal>


            <ReactModal 
              ariaHideApp={false}
              isOpen={showInfoDialog} 
              onRequestClose={handleHideModal} 
              shouldCloseOnOverlayClick={true}
              style={{
                overlay: {
                  backgroundColor: " rgba(0, 0, 0, 0.4)",
                  width: "100%",
                  height: "100vh",
                  zIndex: "10",
                  position: "fixed",
                  top: "0",
                  left: "0",
                },
                content: {
                  width: "80%",
                  height: "700px",
                  zIndex: "150",
                  position: "absolute",
                  top: "20%",
                  left: "10%",
                  borderRadius: "10px",
                  backgroundColor: "white",
                  justifyContent: "center",
                  overflow: "auto",
                  margin: '10%'
                },
              }}>

<div className='serviceDetail'>
              <div className='inner'>
                <div className='radioList' style={{marginBottom:"40px"}}>
                  <div style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginBottom: "14px",
                    }}>출입방법</div>
                  <label style={styleLabel}>
                    <div style={{flex: "1 0"}}>오시면 초인종을 눌러주세요</div>
                    <div style={{flex: "0 0 auto"}}>
                      <input style={{display: "none"}} checked={radioChecked[0]} type="radio" name="radio" onChange={() => handleRadioChange(0)}></input>
                      <svg style={{display:(radioChecked[0])?"block":"none"}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#45D88E" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#45D88E"/></svg>
                      <svg style={{display:(radioChecked[0])?"none":"block"}}  width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#F1EEE1" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#F1EEE1"/></svg>
                    </div>
                  </label>
                  <label style={styleLabel}>
                    <div style={{flex: "1 0"}}>오시면 전화주세요</div>
                    <div style={{flex: "0 0 auto"}}>
                      <input style={{display: "none"}} checked={radioChecked[1]} type="radio" name="radio" onChange={() => handleRadioChange(1)}></input>
                      <svg style={{display:(radioChecked[1])?"block":"none"}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#45D88E" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#45D88E"/></svg>
                      <svg style={{display:(radioChecked[1])?"none":"block"}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#F1EEE1" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#F1EEE1"/></svg>
                    </div>
                  </label>
                  <label style={styleLabel}>
                    <div style={{flex: "1 0"}}>직접 입력</div>
                    <div style={{flex: "0 0 auto"}}>
                      <input style={{display: "none"}} checked={radioChecked[2]} type="radio" name="radio" onChange={() => handleRadioChange(2)}></input>
                      <svg style={{display:(radioChecked[2])?"block":"none"}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#45D88E" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#45D88E"/></svg>
                      <svg style={{display:(radioChecked[2])?"none":"block"}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="9" cy="9" r="8" stroke="#F1EEE1" stroke-width="2"/><circle cx="9" cy="9" r="5" fill="#F1EEE1"/></svg>
                    </div>
                  </label>
            {/*}      <textarea className='borderTextArea' ref={enterRef} style={{display: radioChecked[2]? "block":"none"}}rows="3" placeholder="예)공동현관 비밀번호 0000입니다. 들어오셔서 집앞에서 초인종을 눌러주세요."></textarea>   */}
                  <textarea className='borderTextArea' value={enterInfo}  onChange={(event)=>setEnterInfo(event.target.value)} 
                        style={{display: radioChecked[2]? "block":"none"}}rows="3" placeholder="예)공동현관 비밀번호 0000입니다. 들어오셔서 집앞에서 초인종을 눌러주세요."></textarea>
                </div>

                <div style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginBottom: "14px",
                }}>
                  반려동물
                </div>
                <div style={styleToggleBtnWrap}>
                  <button style={petExist?styleToggleBtnWrapBtnOn:styleToggleBtnWrapBtn} onClick={() => setPetExist(true)}>있음</button>  
                  <button style={petExist?styleToggleBtnWrapBtn:styleToggleBtnWrapBtnOn} onClick={() => setPetExist(false)}>없음</button>
                </div>

                <div style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginBottom: "14px",
                }}>
                  CCTV
                </div>
                <div style={styleToggleBtnWrap}>
                  <button style={cctvExist?styleToggleBtnWrapBtnOn:styleToggleBtnWrapBtn} onClick={() => setCctvExist(true)}>있음</button> 
                  <button style={cctvExist?styleToggleBtnWrapBtn:styleToggleBtnWrapBtnOn} onClick={() => setCctvExist(false)}>없음</button>
                </div>
              </div>
                

              <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                      marginTop: "6px",
                      bottom: "20px",
                      position:"absolute",
                      width:"calc(100% - 20px)"
                    }}>
                    <button style={styleCancelButton} onClick={handleHideModalCancel}>취소</button> 
                    <button style={styleModifyButton} onClick={handleModifyGroup3Modal}>수정</button> 
                  </div>
                </div>
            </ReactModal>


            <ReactModal 
              ariaHideApp={false}
              isOpen={showAdditionalDialog} 
              onRequestClose={handleHideModal} 
              shouldCloseOnOverlayClick={true}
              style={{
                  overlay: {
                    backgroundColor: " rgba(0, 0, 0, 0.4)",
                    width: "100%",
                    height: "100vh",
                    zIndex: "10",
                    position: "fixed",
                    top: "0",
                    left: "0",
                  },
                  content: {
                    width: "80%",
                    height: "520px",
                    zIndex: "150",
                    position: "absolute",
                    top: "20%",
                    left: "10%",
                    borderRadius: "10px",
                    backgroundColor: "white",
                    justifyContent: "center",
                    overflow: "auto",
                    margin: '10%'
                  },
                }}>
                <div className='serviceDetail'>
                  <div className="inner">
                    <div style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginBottom: "14px",
                    }}>
                      도움이 필요한 내용을 상세하게 알려주세요
                    </div>
               {/*}     <textarea className='borderTextArea' ref={detailRef} rows="5" placeholder="예)암검사 정기 검진입니다. 9시 예약으로 검진 이동 및 검진 후 처방전 수령, 추가 예약 일자 확인 등 도움이 필요합니다."></textarea>  */}
                    <textarea className='borderTextArea' value={helpDetail}  onChange={(event)=>setHelpDetail(event.target.value)} 
                        rows="5" placeholder="예)암검사 정기 검진입니다. 9시 예약으로 검진 이동 및 검진 후 처방전 수령, 추가 예약 일자 확인 등 도움이 필요합니다."></textarea>

                    <div style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      marginBottom: "14px",
                    }}>
                      추가적으로 안내해 주실 내용을<br/> 
                      기재해 주세요
                    </div>
                {/*}    <textarea className='borderTextArea' ref={additionalRef} placeholder="예)없음"></textarea>   */}
                    <textarea className='borderTextArea' value={additionalDetail}  onChange={(event)=>setAdditionalDetail(event.target.value)} 
                        rows="5" placeholder="예)없음"></textarea>
                  </div>
                  <div style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "14px",
                      marginTop: "6px"
                    }}>
                    <button style={styleCancelButton} onClick={handleHideModalCancel}>취소</button>  
                    <button style={styleModifyButton} onClick={handleModifyGroup4Modal}>수정</button> 
                  </div>
                </div>
              
            </ReactModal>
 
          </div> 
 
    )
  }



  export  function ServiceDataPaymentShortlView(props) {
 
    function numberWithCommas(x) {
      if(x==undefined)
        return "0"
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function handleReceipt() {
      alert("준비중입니다.")
    }

    return (
 

      <div className="section"  hidden={ !((props.data.status == 1) | (props.data.status == 2))} style={{
        padding: '20px'
    }}>
        <button className='bill' onClick={handleReceipt} hidden={props.isSupporter}>결제영수증</button>
        <div className='item'>
            <div className="value">결제 정보</div>
            <div className="between">
                <p>상품</p>
                <p>{props.data.serviceTitle} {props.data.serviceDuration}</p>
            </div>
            <div className="between" hidden={props.isSupporter}>
                <p>결제 일시</p>
                <p>{props.data.paymentDateTime}</p>
            </div>
            <div className="between">
                <p>결제액</p>
                <p>{numberWithCommas(props.data.total_price)}원</p>
            </div>

      </div>
    </div>

    )
  }



  export  function ServiceDataSettlementShortlView(props) {
 
    function numberWithCommas(x) {
      if(x==undefined)
        return "0"
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
 

    return (
 

      <div className="section" style={{
        padding: '20px'
    }}> 
        <div className='item'>
            <div className="value">정산 정보</div>
 
            <div className="between">
                <p>정산액</p>
                <p>{(props.data.settledPrice == 0)?'정산 예정':numberWithCommas(props.data.settledPrice)+'원'}</p>
            </div>
            <div className="between">
                <p>정산일</p>
                <p hidden={(props.data.settledPrice == 0)}>{props.data.settledDate}</p>
            </div>
        </div>
    </div>

    )
  }