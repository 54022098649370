import React, {useState} from "react";
import ReactModal from 'react-modal';
import "../../src/css/style.css"

function Screen5() {

    const [showRegionDialog, setShowRegionDiaglog] = useState(false)

    function handleRegionClick() {
        setShowRegionDiaglog(true)
    }
    function handleHideRegionModal() {
        setShowRegionDiaglog(false)
    }

    return (
        <>
            <div className="serviceSelect">
                <div className="pageBack"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path><path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path></svg></div>
                <div className="inner32">
                    <h1 className="pageTit" style={{
                        marginBottom:'28px'
                    }}>
                        2. 학력 및 경력 증명 서류 확인
                    </h1>

                    <p style={{
                        fontSize:'14px',
                        marginBottom:'20px'
                    }}>
                    상담, 운동/체조, 노래, 만들기/공예, 사진/영상촬영,<br/>
                    외국어, 미술 분야 등의 서포터를 진행하기 위해<br/>
                    아래 서류를 첨부해 주세요
                    </p>

                    <p style={{
                        fontSize:'14px',
                        marginBottom:'50px'
                    }}>
                    관련 전공 및 학력을 확인할 수 있는<br/><br/>
                    1. 대학졸업증명서<br/>
                    2. 자격증<br/>
                    3. 기타 자격을 증명할 수 있는 서류
                    </p>

                    <div className="btnWrap" style={{
                        gap:'23px'
                    }}>
                        <button style={{
                            flex: '1 0 0',
                            height: '45px'
                        }} className="cta" onClick={handleRegionClick}>등록하기</button>
                        <button style={{
                            flex: '1 0 0',
                            height: '45px'
                        }} className="goldBtn">해당 사항 없음</button>
                    </div>


                    <ReactModal 
                        isOpen={showRegionDialog} 
                        // onRequestClose={handleHideRegionModal} 
                        >
                            <div className="modalDim" onClick={handleHideRegionModal}></div>

                            <div className="bottomPannel">
                                <button className="item">사진 촬영</button>
                                <button className="item">앨범에서 가져오기</button>
                                <button className="item" onClick={handleHideRegionModal}>취소</button>
                            </div>
                    
                    </ReactModal>
                    
                </div>

                <div className="ctaWrap bottomCta"><button className="cta">확인</button></div>
            </div>
        </>
    )
}


export default Screen5