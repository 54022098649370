import React, { useState, useContext, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import {AppContext} from '../../App'; 
import ServiceDataShortView from './ServiceDataShortView';
import WriteFeedbackModal from './WriteFeedbackModal';
import { ServiceDataPaymentShortlView, ServiceDataSettlementShortlView } from './ServiceDataShortView';
import * as moment from 'moment';

function CompletedDetailItem(props) { 
   
    const navigate = useNavigate(); 


    const [showFeedbackDialog, setShowFeedbackDiaglog] = useState(false)

    const handleHideFeedbackModal = () => {
      setShowFeedbackDiaglog(false)
     }
 

    function handleFeedbackClick() {
      if(props.data.rate != 0)
        alert("이미 후기를 작성하였습니다.")
      else
        setShowFeedbackDiaglog(true)
    }


    function handleBackClick() { 
        navigate(-1)
    }
   
    let bShowSelect = false
    let bShowCheck = false
    if(props.data.status == 0)
    {

      const now = moment()
      const startDate = moment(now.format("YYYY-MM-DD"), "YYYY-MM-DD")
      const endDate = moment(props.data.serviceDate, "YYYY년 MM월 DD일")
      const daysDifference = endDate.diff(startDate, 'days');

      if(daysDifference<0)
      {
        bShowSelect = false
        bShowCheck = false
      }
      else
      {
        bShowSelect = true
        bShowCheck = false
      }
        
    }
    else if(props.data.status == 1) 
    {       
      bShowSelect = false
      bShowCheck = true
    }
    else if(props.data.status == 5) 
    {
      bShowSelect = false
      bShowCheck = false
    }

    return (
      <div className='bg'>

        <div className='card'>
        <ServiceDataShortView data={props.data}></ServiceDataShortView>
        <ServiceDataPaymentShortlView isSupporter={false} data={props.data}></ServiceDataPaymentShortlView>

        <div className="btnWrap" style={{
                marginTop:'40px'
            }}>
                <button className="btn primary" onClick={handleBackClick}>확인</button>
                <button className="btn primary" onClick={handleFeedbackClick}>서포터 후기 작성</button>
            </div>
        </div>

        <WriteFeedbackModal serviceData={props.data} showFeedbackDialog={showFeedbackDialog} handleHideFeedbackModal={handleHideFeedbackModal}></WriteFeedbackModal>

      </div>
    )
  }
   
  function CompletedSupporterDetailItem(props) {
    const [showDetail, setShowDetail] = useState(false)
  
    const navigate = useNavigate(); 
 
    function handleBackClick()
    { 
      navigate(-1)
    }
 
    
    return (
      <div className='bg'>
  
        <div className='card'>
        <ServiceDataShortView data={props.data}></ServiceDataShortView>
        <ServiceDataPaymentShortlView isSupporter={true} data={props.data}></ServiceDataPaymentShortlView>
        <ServiceDataSettlementShortlView isSupporter={true} data={props.data}></ServiceDataSettlementShortlView>

          <div className='btnWrap'>
            <button className='btn primary' onClick={handleBackClick}>확인</button> 
          </div>
        </div>
      </div>
    )
  }
  
  
  export default function CompletedDetail() {
  
    const navigate = useNavigate(); 
    
    const {globalUserData, setGlobalUserData, modeSupporter, setModeSupporter} = useContext(AppContext); 
    const[serviceData, setServiceData] = useState([])
    const [isReady, setIsReady] = useState(false)
  

    const location = useLocation();	
    const servicedata = location.state.servicedata;	


    function handleBackClick() { 
        navigate(-1)
    }


    function handleMoreClick() { 
      alert("준비중입니다.")
    }

    const initRead = async() => { 
    
          setIsReady(true)
 
    }
    useEffect(() => {initRead()},[]);
    
    if(!isReady) 
    { 
      return (
        <div></div>
      )
    }
    else
    {
      if(modeSupporter)
      {
        return (
   
        <div className='subPage serviceSelect'>
        <div className="inner32">
          <div className="pageTit">{servicedata.serviceDate}<br/>서포터 완료내역</div>
        </div>
        
        <div className=''> 
          <div className="pageBack" onClick={handleBackClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
          </div>
          {/* <div className="moreMenu" onClick={handleSupporterMoreClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm0-2.6A2.2 2.2 0 1 0 9.999.8a2.2 2.2 0 0 0 .002 4.4m0 9.6a2.2 2.2 0 1 0 0 4.402a2.2 2.2 0 0 0 0-4.402"/></svg>
          </div> */}
          <CompletedSupporterDetailItem data={servicedata} />
        </div>
        </div>
          
        )  
      }
      else
      {

        return (             

          <div className='subPage serviceSelect'>
          <div className="menuOption" style={{
            position:'absolute',
            top:'14px',
            right:'12px'
          }}>
            {/*
            <svg onClick={menuOptionToggle} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="6" r="1.5" fill="black"/>
                <circle cx="12" cy="12" r="1.5" fill="black"/>
                <circle cx="12" cy="18" r="1.5" fill="black"/>
            </svg>
            <div className={`menu ${menuOption}`}>
                <div className="dropMenu">
                    <button>요청 취소하기</button>
                </div>
                <div className="dim" onClick={menuOptionToggle}></div>
            </div>
            */}
          </div>
          <div className="inner32">
            <div className="pageTit">{servicedata.serviceDate}<br/>완료내역</div>
          </div>
          <div className=''>
            <div className="pageBack" onClick={handleBackClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg>
            </div>             
            {/* <div className="moreMenu" onClick={handleMoreClick}>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20"><path fill="currentColor" d="M10.001 7.8a2.2 2.2 0 1 0 0 4.402A2.2 2.2 0 0 0 10 7.8zm0-2.6A2.2 2.2 0 1 0 9.999.8a2.2 2.2 0 0 0 .002 4.4m0 9.6a2.2 2.2 0 1 0 0 4.402a2.2 2.2 0 0 0 0-4.402"/></svg>
            </div> */}
            <CompletedDetailItem data={servicedata} />
          </div>
      </div>


 
  
 
        )  
      }
    }
  };
   
  