import React, {useState} from "react";
import ReactModal from 'react-modal';
import "../../src/css/style.css"

function Screen10() {

    const [showRegionDialog, setShowRegionDiaglog] = useState(false)

    function handleRegionClick() {
        setShowRegionDiaglog(true)
    }
    function handleHideRegionModal() {
        setShowRegionDiaglog(false)
    }

    return (
        <>
            <div className="serviceSelect">
                <div className="pageBack"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path><path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path></svg></div>
                <div className="inner32">
                    <h1 className="pageTit" style={{
                        marginBottom:'28px'
                    }}>
                        5. 모의 면접
                    </h1>

                    <div className="termsBox" style={{
                        minHeight:'70vh'
                    }}>
                        면접 내용
                    </div>
                </div>

                <div className="bottomCta" style={{
                    marginBlock:'30px',
                    padding:'0 12px'
                }}>
                    <button className="cta" style={{}}>확인</button>
                </div>
            </div>
        </>
    )
}


export default Screen10