import React, {useState} from "react";
import ReactModal from 'react-modal';
import "../../src/css/style.css"

function Screen9() {

    const [showRegionDialog, setShowRegionDiaglog] = useState(false)

    function handleRegionClick() {
        setShowRegionDiaglog(true)
    }
    function handleHideRegionModal() {
        setShowRegionDiaglog(false)
    }

    return (
        <>
            <div className="serviceSelect">
                <div className="pageBack"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path><path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"></path></svg></div>
                <div className="inner32">
                    <h1 className="pageTit" style={{
                        marginBottom:'28px'
                    }}>
                        4. 행동강령 서약서 동의
                    </h1>

                    <div className="termsBox" style={{
                        maxHeight:'calc(100vh - 270px)'
                    }}>
                        서약서 내용
                    </div>
                </div>

                <div className="bottomCta" style={{
                    marginBlock:'30px',
                    padding:'0 12px'
                }}>
                    <button className="cta" style={{
                        marginBottom:'13px'
                    }}>네 동의합니다</button>
                    <button className="goldBtn" onClick={handleRegionClick} style={{
                        height:'45px'
                    }}>아니요 동의하지 않습니다</button>
                </div>

                <ReactModal 
                    isOpen={showRegionDialog} 
                    onRequestClose={handleHideRegionModal} 
                    shouldCloseOnOverlayClick={false}
                    >
                        <div className="modalDim"></div>

                        <div className="confirm">
                            <div className="desc">
                                범죄경력회보서를<br/>
                                비사이미에 제공하는 것에<br/>
                                동의하지 않을 경우<br/>
                                ‘공식인증 서포터’ 인증이 어렵습니다.<br/><br/>
                                정말 동의하지 않으시겠습니까?<br/>
                            </div>
                            <div className="btnWrap">
                                <button className="primary" onClick={handleHideRegionModal}>동의 합니다</button>
                                <button className="primary">동의하지 않습니다</button>
                            </div>
                        </div>
                    
                </ReactModal>
            </div>
        </>
    )
}


export default Screen9