import ServiceDataShortlView from './ServiceDataShortView';
import { ServiceDataSecondShortlView } from './ServiceDataShortView';

export default function ServiceDataDetailView(props) {

    return (
      <div> 
          <ServiceDataShortlView data={props.data} canModify={props.canModify}></ServiceDataShortlView>
          <ServiceDataSecondShortlView data={props.data} canModify={props.canModify}></ServiceDataSecondShortlView>
      </div>
    )
}