import React, {useState, useContext, useEffect} from 'react';

import { Routes, Route, useNavigate, useLocation } from "react-router-dom"; 
import BottomNavigation from './BottomNavigation';
import CompletedList from './componentsUsage/CompletedList';
import OngoingList from './componentsUsage/OngoingList';
import OngoingDetail from './componentsUsage/OngoingDetail';
import CompletedDetail from './componentsUsage/CompletedDetail';
import SupporterSelect from './componentsUsage/SupporterList';
import SupporterSelectDetail from './componentsUsage/SupporterSelectDetail';
import SupporterSelectComplete from './componentsUsage/SupporterSelectComplete';
import SelectedServiceDetail from './componentsUsage/SelectedServiceDetail';
import ShowSupporterInfo from './componentsUsage/ShowSupporterInfo';
import WriteFeedback from './componentsUsage/WriteFeedbackModal';

import NeedLogin from './NeedLogin';
import { AppContext } from '../App'; 


function UsageMain() {

    const {globalUserData, setGlobalUserData, modeSupporter, setModeSupporter} = useContext(AppContext); 
 
    const [activeIndex, setActiveIndex] = useState(globalUserData.usageIndex);

    const tabContArr=[
      {
          tabTitle:(
              <li key="0" className={activeIndex===0 ? "is-active nav-item" : "nav-item"} onClick={()=>tabClickHandler(0)}>
                {modeSupporter?"서포터 신청내역":"요청내역"}
              </li>
          ),
          tabCont:(
              <OngoingList/> 
          )
      },
      {
          tabTitle:(
              <li key="1" className={activeIndex===1 ? "is-active nav-item" : "nav-item"} onClick={()=>tabClickHandler(1)}>
                  {modeSupporter?"서포터 완료내역":"완료내역"}
              </li>
          ),
          tabCont:(
            <CompletedList/> 
          )
      }
    ];
  

  
    const tabClickHandler=(index)=>{
    setActiveIndex(index)
    let globalUserData_ = {...globalUserData}
    globalUserData_.usageIndex = index
    setGlobalUserData(globalUserData_) 
    }
  

  if(globalUserData.userSeqNum > 0) {
    return (
    
        <div>

          <div className='topWrap'>
            <div className="pageHeader">
              <div className="inner18">
                <h2>이용현황</h2>
              </div>
            </div>
            <nav className="navbar navbar-right" rold="navigation">
              <ul className="tabs is-boxed w-100 nav">
                <div className="d-flex flex-row justify-content-around w-100">
                  {tabContArr.map((section, index)=>{
                    return section.tabTitle
                  })}
                </div>
              </ul>
            </nav>
          </div>
          <div>
            { tabContArr[activeIndex].tabCont }
          </div>
  
          <BottomNavigation/>
        </div>
      )
  }
  else {
    return (
      <div>
      <NeedLogin showBack="false"/>
      <BottomNavigation/>
      </div>
    )
  }
}
 
export default function Usage(props) {
 
  return (
    <div>
      <Routes>
        <Route path="*" element={<UsageMain />} />
        <Route path="OngoingDetail" element={<OngoingDetail />} />
        <Route path="CompletedDetail" element={<CompletedDetail />} />
        <Route path="SupporterSelect" element={<SupporterSelect />} />
        <Route path="SupporterSelectDetail" element={<SupporterSelectDetail />} /> 
        <Route path="SupporterSelectComplete" element={<SupporterSelectComplete />} /> 
        <Route path="SelectedServiceDetail" element={<SelectedServiceDetail />} />  
        <Route path="ShowSupporterInfo" element={<ShowSupporterInfo />} />  
        <Route path="WriteFeedback" element={<WriteFeedback />} />  
      </Routes>
    </div>
  )
};