import React, {useState} from "react";
import ReactModal from 'react-modal';
import "../../../src/css/style.css"


function WriteFeedbackModal(props) {


    let init_isPositive = true
    let init_indexPositive = 1
    let init_indexNegative = 1
    let init_rate = 3
    if(props.serviceData.rate > 0)
    {
        init_rate = props.serviceData.rate
    }   
    if(props.serviceData.feedback>0)
    {
        init_isPositive = true 
        init_indexPositive = props.serviceData.feedback
    }
    else if(props.serviceData.feedback<0)
    {
        init_isPositive = false
        init_indexNegative = -props.serviceData.feedback
    }

    const [isPositive, setIsPositive] = useState(init_isPositive)
    const [indexPositive, setIndexPositive] = useState(init_indexPositive)
    const [indexNegative, setIndexNegative] = useState(init_indexNegative)
    const [rate, setRate] = useState(init_rate)

    function handleModeChange() {
        setIsPositive(!isPositive)
    }

    function handleRate(rate) {
        setRate(rate)
    }

    function handleRadioPositiveChange(index) {
        setIndexPositive(index)
    }
    
    function handleRadioNegativeChange(index) {
        setIndexNegative(index)
    }

    async function handleSaveFeedback() {

        let _feedback = indexPositive
        if(!isPositive)
            _feedback = -indexNegative

        let url = "https://rest.besideme.life/rest/v1/servicedata/"+props.serviceData.serviceSeqNum;
        let params = {rate : rate, feedback : _feedback}

        await fetch(url, {
        method : "PUT",       
        body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
        })
        .then(response=>{  
        return response.json();
        })
        .then(data=>{  
        if(data.code == "error")
            {
            alert(data.message);
            return;
            }
            alert("서포터 후기가 저장되었습니다.")
            props.handleHideFeedbackModal()  
        })
        .catch(err => {
            alert(err.message);
        }); 

    }


    return (
        <ReactModal
        ariaHideApp={false}
        isOpen={props.showFeedbackDialog} 
        onRequestClose={props.handleHideFeedbackModal} 
        shouldCloseOnOverlayClick={false}
        >
            <div className='bottomModal feedback'>
                <div className='closeBtn' onClick={props.handleHideFeedbackModal}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className='tit font16'>서포터 후기 작성</div>
  
                <div style={{
                    padding:'23px 35px 0'
                }}>
                    <div className="starWrapBox">
                        <strong>별점</strong>
                        <div className="starWrap">
                            <div className={"star " + ((rate>=1)?"fill":"")} onClick={()=>handleRate(1)}></div>
                            <div className={"star " + ((rate>=2)?"fill":"")} onClick={()=>handleRate(2)}></div>
                            <div className={"star " + ((rate>=3)?"fill":"")} onClick={()=>handleRate(3)}></div>
                            <div className={"star " + ((rate>=4)?"fill":"")} onClick={()=>handleRate(4)}></div>
                            <div className={"star " + ((rate>=5)?"fill":"")} onClick={()=>handleRate(5)}></div>
                        </div>
                    </div>
  
                    <div className="control">
                        <strong>추천해요</strong>
                         <div className="arrow" hidden={isPositive} onClick={handleModeChange}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#E0DCC9"/>
                            <path d="M18 10L12 16L6 10" stroke="#33363F" stroke-width="2"/>
                            </svg>
                        </div> 
                    </div>
                    <ul className="panel" hidden={!isPositive}>
                        <li key="1"><label>
                            <input type="radio" name="radio1" checked={(indexPositive==1)} onChange={() => handleRadioPositiveChange(1)}></input>
                            <div className="icon"></div>
                            친절해요
                        </label></li>
                        <li key="2"><label>
                            <input type="radio" name="radio1" checked={(indexPositive==2)} onChange={() => handleRadioPositiveChange(2)}></input>
                            <div className="icon"></div>
                            준비성이 좋아요
                        </label></li>
                        <li key="3"><label>
                            <input type="radio" name="radio1" checked={(indexPositive==3)} onChange={() => handleRadioPositiveChange(3)}></input>
                            <div className="icon"></div>
                            시간 약속을 잘 지켜요
                        </label></li>
                        <li key="4"><label>
                            <input type="radio" name="radio1" checked={(indexPositive==4)} onChange={() => handleRadioPositiveChange(4)}></input>
                            <div className="icon"></div>
                            전문적이에요
                        </label></li>
                        <li key="5"><label>
                            <input type="radio" name="radio1" checked={(indexPositive==5)} onChange={() => handleRadioPositiveChange(5)}></input>
                            <div className="icon"></div>
                            서비스가 꼼꼼해요
                        </label></li>
                        <li key="6"><label>
                            <input type="radio" name="radio1" checked={(indexPositive==6)} onChange={() => handleRadioPositiveChange(6)}></input>
                            <div className="icon"></div>
                            소통이 원활해요
                        </label></li>
                    </ul>
  
                    <div className="control">
                        <strong>추천하지 않아요</strong>
                        <div className="arrow" hidden={!isPositive} onClick={handleModeChange}>
                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="12" cy="12" r="12" fill="#E0DCC9"/>
                            <path d="M18 10L12 16L6 10" stroke="#33363F" stroke-width="2"/>
                            </svg>
                        </div>
                    </div>
                     <ul className="panel" hidden={isPositive}>
                        <li key="1"><label>
                            <input type="radio" name="radio2" checked={(indexNegative==1)} onChange={() => handleRadioNegativeChange(1)}></input>
                            <div className="icon"></div>
                            불친절해요
                        </label></li>
                        <li key="2"><label>
                            <input type="radio" name="radio2" checked={(indexNegative==2)} onChange={() => handleRadioNegativeChange(2)}></input>
                            <div className="icon"></div>
                            준비성이 부족해요
                        </label></li>
                        <li key="3"><label>
                            <input type="radio" name="radio2" checked={(indexNegative==3)} onChange={() => handleRadioNegativeChange(3)}></input>
                            <div className="icon"></div>
                            시간 약속을 잘 지키지 않아요
                        </label></li>
                        <li key="4"><label>
                            <input type="radio" name="radio2" checked={(indexNegative==4)} onChange={() => handleRadioNegativeChange(4)}></input>
                            <div className="icon"></div>
                            전문적이지 않아요
                        </label></li>
                        <li key="5"><label>
                            <input type="radio" name="radio2" checked={(indexNegative==5)} onChange={() => handleRadioNegativeChange(5)}></input>
                            <div className="icon"></div>
                            서비스가 불성실해요
                        </label></li>
                        <li key="6"><label>
                            <input type="radio" name="radio2" checked={(indexNegative==6)} onChange={() => handleRadioNegativeChange(6)}></input>
                            <div className="icon"></div>
                            소통이 원활하지 않아요
                        </label></li>
                    </ul> 
                </div>
  
                <button className='cta' style={{marginBottom:"20px"}} onClick={handleSaveFeedback}>확인</button>
            </div>
        </ReactModal>
    )
  
  }

export default WriteFeedbackModal