import React, {useState, useEffect, useContext} from 'react';
import { useNavigate, useLocation } from "react-router-dom"; 
import { AppContext } from '../../App';
 

export function Category({data, expanded}) {
  const navigate = useNavigate();
  
  function handleCategoryClick(categoryseqnum, categorysubseq, categorysubname) {
    
    navigate("/Home/ServiceDateTime", { replace: false, state : {categoryseqnum: categoryseqnum, categorysubseq: categorysubseq, categorysubname: categorysubname} }) 

  }

  return (
    <div>
      { 
        data.subCategory.map((subcategory) =>
        (   
          <div className='item' onClick={() => handleCategoryClick(data.categorySeqNum, subcategory.categorySubSeqNum, subcategory.categorySubName)}> 
            {subcategory.categorySubName}
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/>
            </svg>
          </div> 
             
        )) 
      }

  </div>
  )
}

export default function ServiceSelect() {
  
  const navigate = useNavigate();
  
  //const { phoneNumber, uniqueID, accessKey, seqNumber, setPhoneNumber, setUniqueID, setAccessKey, setSeqNumber } = useContext(AppContext); 
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 

  const [categoryData, setCategoryData] = useState([])
  const [categoryExpanded, setCategoryExpanded] = useState(new Array(9).fill(false))


  const location = useLocation();	
  let category = location.state.category;	


  const initRead = async() => {

    if(category != -1)
    {
      let categoryExpanded_ = [...categoryExpanded]
      categoryExpanded_[category] = !categoryExpanded[category]
      setCategoryExpanded(categoryExpanded_)
    }

    let url = "https://rest.besideme.life/rest/v1/category";

    await fetch(url)
    .then(response=>{ 
        return response.json();
    })
    .then(data=>{ 
      if(data.code == "error")
        {
          alert(data.message);
          return;
        }
     setCategoryData(data.result)

    })
    .catch(err => {
        alert(err.message);
    }); 
  }

  useEffect(() => {initRead()},[]);

  function handleBackClick() {  
    navigate("/", { replace: true}) 
  }
   
  function handleCategoryClick(index) {
    let categoryExpanded_ = [...categoryExpanded]
    categoryExpanded_[index] = !categoryExpanded[index]
    setCategoryExpanded(categoryExpanded_)
  }
 
    return (
        <div className='serviceSelect'>
            <div className='inner32'>
              <div className='pageBack' onClick={handleBackClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"/>
                </svg>
              </div>
              <h1 className='pageTit'>어떤 도움이 필요하신가요?</h1>
              { 
                  categoryData.map((category, index) =>
                  (  
                    <div className='accodion'>
                      <div className="acc-top" onClick={() => handleCategoryClick(index)}>
                        <h4>{category.categoryName}</h4>
                        <div className="acc-arr">
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18 9L12 15L6 9" stroke="#33363F" stroke-width="2"/>
                          </svg>
                        </div>
                      </div>

                        <div className="acc-body">
                          {categoryExpanded[index] && <Category data={category}></Category>}  
                        </div>
                    </div>
                  )) 
                }
            </div>

        </div>
      )

};
 