import React, {useState, useEffect, useContext} from 'react';
import ReactModal from 'react-modal';
import { useNavigate, useLocation } from "react-router-dom"; 
import { AppContext } from '../../App';
import SupporterSelectComplete from './SupporterSelectComplete'; 
import { is } from 'date-fns/locale';
 

export default function SupporterSelectDetail() {
  
  const navigate = useNavigate();
   
  const {globalUserData, setGlobalUserData} = useContext(AppContext); 

  const [showPaymentMethod, setShowPaymentMethod] = useState(false)

  const [isCard, setIsCard] = useState(true)
  const [isKakaoPay, setIsKakaoPay] = useState(false) 
  const [isNaverPay, setIsNaverPay] = useState(false)
  const [isAgreed, setIsAgreed] = useState(false)

  const location = useLocation();	 
  const servicedata = location.state.serviceData
  const supporterdata = location.state.supporterData
 
  function handleHideModal() {
    setShowPaymentMethod(false)
  }

 function handlePaymentSelect() {
    setShowPaymentMethod(false)
    let method = ''
    if(isCard)
      method='card'
    else if(isKakaoPay)
      method='kakaopay'
    else if(isNaverPay)
      method='naverpayCard'

    window.location.href="https://rest.besideme.life/pay_template.php?method="+method+"&amount="+ supporterdata.total_price+"&serviceid="+servicedata.serviceSeqNum+"&supporterid="+supporterdata.userSeqNum;
}

  async function handlePayment() {

    setIsAgreed(false)
    setShowPaymentMethod(true)
    
  }

  function handleCardClick() {
      setIsCard(true)
      setIsKakaoPay(false)
      setIsNaverPay(false)
  }

  function handleKakaoPayClick() {
    setIsCard(false)
    setIsKakaoPay(true)
    setIsNaverPay(false)
  }

  function handleNaverPayClick() {
      setIsCard(false)
      setIsKakaoPay(false)
      setIsNaverPay(true)
  }

  function handleCheckChange() {
    setIsAgreed(!isAgreed)
  }
  
  async function handlePaySuccess() {


    let url = "https://rest.besideme.life/rest/v1/servicedata/"+servicedata.serviceSeqNum;
    let params = {status : 1, selectedSup : supporterdata.userSeqNum, suggestSeqNum : supporterdata.suggestSeqNum, price:supporterdata.price, total_price:supporterdata.total_price}

    await fetch(url, {
      method : "PUT",       
      body: JSON.stringify(params)   //실제 데이터 파싱하여 body에 저장
    })
    .then(response=>{  
      return response.json();
    })
    .then(data=>{  
      if(data.code == "error")
        {
          alert(data.message);
          return;
        }
        navigate('/Usage/SupporterSelectComplete', { replace: false, state : {servicedata: servicedata, supporterdata: supporterdata}})
    })
    .catch(err => {
        alert(err.message);
    }); 


    
  }
 

  function numberWithCommas(x) {
    if(x==undefined)
        return "0"
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function handleBackClick() {  
    navigate(-1) 
  }


  useEffect(() => {

    const eventFromAndroid = async (event) => {
      handlePaySuccess()
    }

    window.addEventListener('javascriptFunction', eventFromAndroid);
 
    return () => {
        window.removeEventListener('javascriptFunction', eventFromAndroid);
    };
    
}, []);


function handleSupporterClick() {
  navigate("/Usage/ShowSupporterInfo", { replace: false, state : {supseqnum: supporterdata.userSeqNum}})
}

    
    return (
      <>
        <div className='serviceSelect bgBeige' style={{
          height:'118px',
          background:'#fff',
          paddingBottom:'11px',
          paddingTop:'47px',
        }}>
            <div className='inner32' style={{
              paddingRight:'23px'
            }}>
              <div className='pageBack' onClick={handleBackClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"/>
                </svg>
              </div>
              <div style={{
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
              }}>
                <h1 className='pageTit' style={{
                  margin:'0'
                }}>{supporterdata.userName}</h1>
                <div className='profileWrap'>
                  <div className='profile badge' onClick={handleSupporterClick}>
                    {/* 유저 프로필 */}
                    <img src={"https://buddypics.s3.ap-northeast-2.amazonaws.com/"+supporterdata.userSeqNum+".jpg?${new Date()}"} onError={(e) => e.target.src = 'https://imgcdn.stablediffusionweb.com/2024/6/12/4d688bcf-f53b-42b6-a98d-3254619f3b58.jpg'}></img>
                    <svg hidden={supporterdata.isCertified == 0} className='checkBadge' width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M28.6072 11.0791L27.615 10.9551L28.6072 11.0791C28.7178 10.1947 28.2297 9.34386 27.4105 8.99275L18.2878 5.08302C17.7847 4.86741 17.2152 4.86741 16.7121 5.08302L7.58941 8.99275C6.77017 9.34385 6.28214 10.1947 6.39269 11.0791L7.46401 19.6497C7.68416 21.4109 8.56513 23.0227 9.92866 24.159L16.2196 29.4014C16.9613 30.0195 18.0386 30.0195 18.7803 29.4014L25.0713 24.159C26.4348 23.0227 27.3158 21.4109 27.5359 19.6497L28.6072 11.0791Z" fill="#45D88E" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                    <path d="M13.125 17.5L17.0688 21.4437C17.2919 21.6668 17.6633 21.6301 17.8384 21.3675L23.3334 13.125" stroke="#33363F" stroke-width="2" stroke-linecap="round"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div className='bg'>
          <div className='card'>
            <div className='value'>신청사유</div>
            <pre style={{whiteSpace: "pre-wrap"}}>{supporterdata.reason}</pre>
            <div className='section' style={{
              marginTop:'24px'
            }}>
              <div className='between' style={{
                margin:'0'
              }}>
                <div className='value'>서포터 비용(시간당)</div> <p>{numberWithCommas(supporterdata.price)}원</p>
              </div>
            </div>
            <div className='section'>
              <div className='value'>참고해 주세요</div>
              <pre style={{whiteSpace: "pre-wrap"}}>{supporterdata.refer}</pre>
            </div>          
          </div>
          {/* <button className="cta" style={{marginTop:'31px'}} onClick={handlePayment}>결제하기</button> */}
          <button className="cta" hidden={showPaymentMethod} style={{marginTop:'31px'}} onClick={handlePayment}>서포터 확정</button>
        </div>


        {/* bottomModal */}
        <ReactModal
          ariaHideApp={false}
          isOpen={showPaymentMethod} 
          onRequestClose={handleHideModal} 
          shouldCloseOnOverlayClick={false}
        >

          <div className='bottomModal'>
            <div className='closeBtn' onClick={handleHideModal}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18 6L6 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
              <path d="M6 6L18 18" stroke="#33363F" stroke-width="2" stroke-linecap="square" stroke-linejoin="round"/>
              </svg>
            </div>
            <div className='tit font16'>{servicedata.serviceDate.substr(6)} {servicedata.serviceTitle}</div>
            <div style={{
              padding:'0 36px'
            }}>
              <div className='between'>
                <span>1시간</span>
                <span>{numberWithCommas(supporterdata.price)}원</span>
              </div>
              <div className='between'>
                <span style={{
                  marginLeft:'38px'
                }}>X {servicedata.serviceDuration}</span>
                <span>{numberWithCommas(supporterdata.total_price)}원</span>
              </div>
            </div>
            <div style={{
              padding:'0 30px'
            }}>
              <div className='divider'></div>
            </div>
            <div style={{
              padding:'0 36px'
            }}>
              <div className='between'>
                <span style={{
                }}>총 결제금액</span>
                <span style={{
                  fontWeight:'bold'
                }}>{numberWithCommas(supporterdata.total_price)}원</span>
              </div>
            </div>
            <div className='divider bold'></div>

            <div className='inner' style={{
              paddingTop:'21px'
            }}>
                <div className='borderBtnWrap'>
                  <button className={'borderBtn ' + (isCard?'ac':'')} onClick={handleCardClick}>신용/체크카드</button>
                  <button className={'borderBtn ' + (isKakaoPay?'ac':'')} onClick={handleKakaoPayClick}><div className='kakaopay'></div></button>
                  <button className={'borderBtn ' + (isNaverPay?'ac':'')} onClick={handleNaverPayClick}><div className='npay'></div></button>
                </div>
                <label className='checkbox'>
                  <input type='checkbox' checked={isAgreed} onChange={handleCheckChange}></input>
                  <svg className='checkIcon' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM13.7682 6.64018C14.1218 6.21591 14.0645 5.58534 13.6402 5.23178C13.2159 4.87821 12.5853 4.93554 12.2318 5.35982L8.63383 9.67736C8.28711 10.0934 8.09219 10.3238 7.93658 10.4653L7.93063 10.4707L7.92422 10.4659C7.75643 10.339 7.54146 10.1272 7.1585 9.74428L5.70711 8.29289C5.31658 7.90237 4.68342 7.90237 4.29289 8.29289C3.90237 8.68342 3.90237 9.31658 4.29289 9.70711L5.74428 11.1585L5.78511 11.1993L5.78512 11.1993C6.11161 11.526 6.4257 11.8402 6.71794 12.0611C7.0453 12.3087 7.47402 12.5415 8.02343 12.5165C8.57284 12.4916 8.97872 12.221 9.28233 11.9448C9.55337 11.6983 9.83772 11.3569 10.1333 11.0021L10.1333 11.0021L10.1703 10.9577L13.7682 6.64018Z" fill="#BAB288"/></svg>
                  (필수) 아래 내용을 확인했으며, 모두 동의합니다.
                </label>
                <div className='goldArrBx'>
                  결제 전 반드시 확인해 주세요!
                  <a href="https://www.notion.so/146c0f44ef6a806fb2f0dbaf20aa3b4d"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/></svg></a>
                </div>

                <button className={'cta ' + (!isAgreed?'disabled':'')} disabled={!isAgreed} onClick={handlePaymentSelect}>결제하기</button>

            </div>
          </div>
        </ReactModal>
      </>
      )

};
 