import React, {useState} from "react";
import ReactModal from 'react-modal';
import "../../src/css/style.css"

function Screen14() {

    const [menuOption, setMenuOption] = useState('')

    function menuOptionToggle() {
        menuOption === 'ac'?setMenuOption(''):setMenuOption('ac')
    }

    return (
        <>
            <div className="subPage serviceSelect">
                <div className="inner32">
                    <div className="pageBack"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15 6L9 12L15 18" stroke="#33363F" stroke-width="2"></path></svg></div>
                    <div className="pageTit" style={{
                        marginBottom:'55px'
                    }}>
                        결제수단 관리
                    </div>
                </div>
                <div className="detaiPageArr">카드 결제<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/></svg></div>
                <div className="detaiPageArr">카카오페이<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/></svg></div>
                <div className="detaiPageArr">카드 결제<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 6L15 12L9 18" stroke="#33363F" stroke-width="2"/></svg></div>
            </div>
        </>
    )
}


export default Screen14